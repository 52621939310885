import "./button.css";
import React from "react";

const Button = ({ textButton, type, activePreloader }) => {
	switch (type) {
		case "primary":
			return (
				<div className="button primary" type="button">
					{activePreloader ?
						<div className="preloader__spinner-button">
							<div className="preloader__spinner"></div>
							Загрузка...
						</div>
						: textButton}
				</div>
			);
		case "secondary":
			return (
				<div className="button secondary" type="button">
					{textButton}
				</div>
			);
		default:
			break;
	}
};
export default Button;
