import React from 'react';
import "./createCardInput.css";
import { productsApi } from '../../api/productsService';

export default function ReturnText({cardInfo}) {
  const { data: report } = productsApi.useGetReportsProductsQuery(cardInfo.slug);
  return (
    report && <div className="createCardInput">
      <h4 className="createCarDInputTitle">Замечания</h4>
      <div className="returnText__container">{report[0]?.reason_text}</div>
    </div>
  )
}
