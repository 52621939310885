import React, { useEffect } from "react";
import "./changePopups/change.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ui/buttons/button";
import { changeStateOpenPopupAdd } from "../../store/popupSlice";
import { useFormAndValidationUserName } from "../../utils/useFormAndValidationUserName";
import { servicesApi } from "../api/servicesService";
import { articlesApi } from "../api/articlesService";
import { tagsApi } from "../api/tagsService";
import { brandsApi } from "../api/brandsService";
import { categoriesApi } from "../api/categoriesService";
import { modelsApi } from "../api/modelsService";
import { colorsApi } from "../api/colorsService";
import { changeStatePreloader } from "../../store/toolkitSlice";

export default function AddCategory() {
	const dispatch = useDispatch();
	const openFolder = useSelector((state) => state.account.openFolder);
	const brandSlug = useSelector((state) => state.toolkit.brandForModelsSlug);
	const openPopupAddCategory = useSelector(
		(state) => state.popup.openPopupAddCategory
	);
	const { values, handleChange, errors, isValid, resetErrors } =
		useFormAndValidationUserName({});

	const [addBrand, { isLoading: isAddingBrand }] =
		brandsApi.useAddBrandsMutation();
	const [addCategory, { isLoading: isAddingCategory }] =
		categoriesApi.useAddCategoriesMutation();
	const [addModel, { isLoading: isAddingModel }] =
		modelsApi.useAddModelsMutation();
	const [addColor, { isLoading: isAddingColor }] =
		colorsApi.useAddColorsMutation();
	const [addService, { isLoading: isAddingService }] =
		servicesApi.useAddServicesMutation();
	const [addArticles, { isLoading: isAddingArticles }] =
		articlesApi.useAddArticlesMutation();
	const [addTags, { isLoading: isAddingTags }] =
		tagsApi.useAddArticlesMutation();
	useEffect(() => {
		if (
			isAddingBrand === true &&
			isAddingCategory === true &&
			isAddingModel === true &&
			isAddingColor === true &&
			isAddingArticles === true &&
			isAddingTags === true &&
			isAddingService === true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		isAddingBrand,
		isAddingCategory,
		isAddingModel,
		isAddingColor,
		isAddingArticles,
		isAddingTags,
		isAddingService,
	]);
	async function HandleSubmitAdd(e) {
		e.preventDefault();
		if (values.category) {
			switch (openFolder) {
				case "Марка авто":
					await addBrand(values.category);
					break;
				case "Модель авто":
					await addModel({
						title: values.category,
						brand: brandSlug,
					});
					break;
				case "Категории":
					await addCategory(values.category);
					break;
				case "Цвет":
					await addColor(values.category);
					break;
				case "Услуги":
					await addService(values.category);
					break;
				case "Артикулы":
					await addArticles(values.category);
					break;
				case "Тэги":
					await addTags(values.category);
					break;
				default:
					break;
			}
		}
		dispatch(changeStateOpenPopupAdd(false));
	}

	return (
		openPopupAddCategory && (
			<form className="popupChangeName">
				<button
					className="overlay"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupAdd(false))
					}></button>
				<div className="change">
					<h2>Новая категория</h2>
					<input
						className="input"
						id="category"
						name="category"
						type="text"
						value={values.category || ""}
						onChange={handleChange}
						required
					/>
					<div className="buttons">
						<button
							className="button"
							type="button"
							onClick={() =>
								dispatch(changeStateOpenPopupAdd(false))
							}>
							<Button textButton="Отмена" type={"secondary"} />
						</button>
						<button
							className="button"
							type="submit"
							onClick={HandleSubmitAdd}>
							<Button textButton="Сохранить" type={"primary"} />
						</button>
					</div>
				</div>
			</form>
		)
	);
}
