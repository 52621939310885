import "./auth.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAuthStatus } from "../../store/authSlice";

const Forgot = () => {
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const dispatch = useDispatch();
	const onSubmit = (e) => {
		e.preventDefault();
		console.log("Я вспомнил пароль. Войти.");
		dispatch(changeAuthStatus("confirm"));
	};
	return (
		<div className="auth">
			<div className="container">
				{pageWidth > 450 ? (
					<h2 className="auth__text">Восстановление пароля</h2>
				) : (
					<h3 className="auth__text">Восстановление пароля</h3>
				)}
				<div className="inputs">
					<div className="input__auth">
						<input
							className="email"
							id="email"
							name="email"
							type="email"
							// value={values.email || ""}
							// onChange={handleChange}
							required
						/>
						<span className="placeholder">E-mail</span>
						{/* <span
							className={`login__error ${
								!isValid ? "login__error_active" : ""
							}`}>
							{errors.email}
						</span> */}
					</div>
				</div>
				<button className="auth__btn" type="submit" onClick={onSubmit}>
					<h4 className="auth__btn-text">Войти</h4>
				</button>
				<button
					className="forgot"
					type="button"
					onClick={() => dispatch(changeAuthStatus("login"))}>
					<span>Я вспомнил пароль. Войти.</span>
				</button>
			</div>
		</div>
	);
};
export default Forgot;
