import React, { useEffect } from "react";
import "./profileSettings.css";
import settings_icon from "../../images/profile/settings_icon.svg";
import settings_password from "../../images/profile/settings_password.svg";
import chevron from "../../images/profile/chevron.svg";
import { useDispatch } from "react-redux";
import {
	changeStateOpenPopupChangeName,
	changeStateOpenPopupChangePassword,
} from "../../store/popupSlice";
import { userApi } from "../../components/api/userService";
import { changeStatePreloader } from "../../store/toolkitSlice";

const ProfileSettings = () => {
	const dispatch = useDispatch();
	const { data: user, isLoading: isGettingUser } = userApi.useGetUserQuery();
	useEffect(() => {
		dispatch(changeStatePreloader(isGettingUser));
	}, [isGettingUser]);
	return (
		<div>
			<h1>Настройки профиля</h1>
			<div className="info">
				<button
					className="block"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupChangeName(true))
					}>
					<div className="block__text">
						<img
							src={settings_icon}
							alt="settings_icon"
							className="icon"
						/>
						<div className="text">
							<p className="second">Имя Фамилия</p>
							<h5 className="first">{user?.full_name}</h5>
						</div>
					</div>
					<div className="chevron">
						<img
							src={chevron}
							alt="chevron"
							className="chevron__image"
						/>
					</div>
				</button>
				<div className="line"></div>
				<button
					className="block"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupChangePassword(true))
					}>
					<div className="block__text">
						<img
							src={settings_password}
							alt="settings_password"
							className="icon"
						/>
						<div className="text">
							<h5 className="first">Изменить пароль</h5>
							<p className="second">Обновлен 2 дня назад</p>
						</div>
					</div>
					<div className="chevron">
						<img
							src={chevron}
							alt="chevron"
							className="chevron__image"
						/>
					</div>
				</button>
			</div>
		</div>
	);
};

export default ProfileSettings;
