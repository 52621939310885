import React from "react";
import Filter from "../filters/filter/filter";
import "./popup.css";
export default function PopupCardInfo({ cardPopupInfo }) {
	return (
		<div className="popup__about">
			<div className="popup__about-block">
				<p className="popup__about-category">Категория</p>
				<div className="popup__map_container">
					{cardPopupInfo?.categories.map((category) => (
						<p className="popup__about-value" key={category.id}>
							{category.title}
							{cardPopupInfo?.categories.length ===
							cardPopupInfo?.categories.indexOf(category) + 1 ? (
								<></>
							) : (
								<span> &nbsp;&#183;&nbsp; </span>
							)}
						</p>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Марка авто</p>
				<div className="popup__map_container">
					{cardPopupInfo?.brand_models.map((brand_model) => (
						<p className="popup__about-value" key={brand_model.id}>
							{brand_model.brand}
							{cardPopupInfo?.brand_models.length ===
							cardPopupInfo?.brand_models.indexOf(brand_model) +
								1 ? (
								<></>
							) : (
								<span> &nbsp;&#183;&nbsp; </span>
							)}
						</p>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Модель авто</p>
				<div className="popup__map_container">
					{cardPopupInfo?.brand_models.map((brand_model) => (
						<p
							className="popup__about-value"
							key={brand_model.title}>
							{brand_model.title}
							{cardPopupInfo?.brand_models.length ===
							cardPopupInfo?.brand_models.indexOf(brand_model) +
								1 ? (
								<></>
							) : (
								<span> &nbsp;&#183;&nbsp; </span>
							)}
						</p>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Услуги</p>
				<div className="popup__map_container">
					{cardPopupInfo?.services.map((service) => (
						<p className="popup__about-value" key={service.id}>
							{service.title}
							{cardPopupInfo?.services.length ===
							cardPopupInfo?.services.indexOf(service) + 1 ? (
								<></>
							) : (
								<span> &nbsp;&#183;&nbsp; </span>
							)}
						</p>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Цвет</p>
				<div className="popup__map_container">
					{cardPopupInfo?.colors.map((color) => (
						<p className="popup__about-value" key={color.id}>
							{color.title}
							{cardPopupInfo?.colors.length ===
							cardPopupInfo?.colors.indexOf(color) + 1 ? (
								<></>
							) : (
								<span> &nbsp;&#183;&nbsp; </span>
							)}
						</p>
					))}
				</div>
			</div>
			<div className="popup__about-block">
				<p className="popup__about-category">Тэги</p>
				{cardPopupInfo?.tags && (
					<ul className="popup__tags">
						{cardPopupInfo?.tags.map((tag) => (
							<Filter
								key={tag.id}
								filter={tag}
								role={"card-tag"}
							/>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}
