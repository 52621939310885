import { authApi } from "./authService";

export const articlesApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addArticles: builder.mutation({
			query: (title) => ({
				url: "/articles/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Articles"],
		}),
		getArticles: builder.query({
			query: () => ({ url: "/articles/" }),
			providesTags: ["Articles"],
		}),
	}),
});
