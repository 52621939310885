import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./catalog.css";
import multi_select from "../../images/tools/multi_select.svg";
import close_multi_select from "../../images/tools/close.svg";
import arrow_downward from "../../images/tools/arrow_downward.svg";
import delete_select from "../../images/tools/delete.svg";
import Sorter from "../ui/dropdown/sorter";
import ViewAgenda from "./view_agenda";
import GridView from "./grid_view";
import { changeSelectedCard, setMultiSelect, setTwoCardsOnRow } from "../../store/catalogSlice";
import Toggle from "../ui/toggle/toggle";
import FileSaver from "file-saver";
import { changeStatePreloader } from "../../store/toolkitSlice";
import { productsApi } from "../api/productsService";

export default function Tools({ cards }) {
	const dispatch = useDispatch();
	const multiSelect = useSelector((state) => state.catalog.multiSelect);
	const twoCardsOnRow = useSelector((state) => state.catalog.twoCardsOnRow);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const selectedCards = useSelector((state) => state.catalog.selectedCards);
	const [
		deleteProducts,
		{
			data: successDeleteProducts,
			error: ErrorDeleteProducts,
			isLoading: LoadingDeleteProducts,
		},
	] = productsApi.useDeleteProductsMutation();

	function handleDownloadSelectedCards() {
		selectedCards.forEach(card => {
			if (card.file) {
				FileSaver.saveAs(card.file, `${card.title}.png`);
			}
			if (card.link) {
				FileSaver.saveAs(card.link, `${card.title}.png`);
			}
		});
	}

	function handleCloseMultiSelect() {
		dispatch(changeSelectedCard([]));
		dispatch(setMultiSelect(false));
	}

	async function HandleDeleteProducts() {
		await selectedCards.forEach(card => {
			deleteProducts(card.slug);
		});
	}

	useEffect(() => {
		if (
			LoadingDeleteProducts == true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [LoadingDeleteProducts]);

	return (
		<>
			{multiSelect ? (
				<>
					<div className="catalog__tool">
						<button
							className="catalog__tool-btn"
							type="button"
							onClick={handleCloseMultiSelect}>
							<img
								className="catalog__tool-img"
								src={close_multi_select}
								alt="button icon"
							/>
							<p className="catalog__tool-btn-text">Выбрано: {selectedCards.length}</p>
						</button>

						<button className="catalog__tool-btn" type="button">
							<img
								className="catalog__tool-img"
								src={multi_select}
								alt="button icon"
							/>
							<p className="catalog__tool-btn-text">
								Выбрать все
							</p>
						</button>
						<button className="catalog__tool-btn" type="button" onClick={handleDownloadSelectedCards}>
							<img
								className="catalog__tool-img"
								src={arrow_downward}
								alt="button icon"
							/>
							<p className="catalog__tool-btn-text">Скачать</p>
						</button>
						<button className="catalog__tool-btn" type="button" onClick={HandleDeleteProducts}>
							<img
								className="catalog__tool-img"
								src={delete_select}
								alt="button icon"
							/>
							<p className="catalog__tool-btn-text">Удалить</p>
						</button>
					</div>
				</>
			) : (
				<>
					<div className="catalog__tool">
						<Sorter />
						{pageWidth > 767 && <Toggle />}
						<button
							className="catalog__tool-btn"
							type="button"
							onClick={() => dispatch(setMultiSelect(true))}>
							<img
								className="catalog__tool-img"
								src={multi_select}
								alt="button icon"
							/>
							<p className="catalog__tool-btn-text">
								{pageWidth < 768
									? "Выбрать"
									: "Выбрать несколько"}
							</p>
						</button>
					</div>
					{pageWidth < 768 && (
						<div className="view">
							<button className="catalog__tool-btn" type="button">
								<div
									className="catalog__tool-img"
									onClick={() =>
										dispatch(setTwoCardsOnRow(true))
									}>
									<GridView twoCardsOnRow={twoCardsOnRow} />
								</div>
							</button>
							<button className="catalog__tool-btn" type="button">
								<div
									className="catalog__tool-img"
									onClick={() =>
										dispatch(setTwoCardsOnRow(false))
									}>
									<ViewAgenda twoCardsOnRow={twoCardsOnRow} />
								</div>
							</button>
						</div>
					)}
					{pageWidth > 767 && (
						<div className="catalog__tool">
							{cards && <p className="catalog__counter">{`Найдено: ${cards}`}</p>}
						</div>
					)}
				</>
			)}
		</>
	);
}
