import React from "react";
import CatalogItem from "../../components/profile/catalogItem";

export default function AllItems() {
	const catalog = [
		{ id: 1, title: "Категории", slug: "Категории!" },
		{ id: 2, title: "Марка авто", slug: "Марка авто!" },
		{ id: 4, title: "Услуги", slug: "Услуги!" },
		{ id: 5, title: "Цвет", slug: "Цвет!" },
		{ id: 6, title: "Продукты", slug: "Продукты!" },
		// { id: 7, title: "Артикулы", slug: "Артикулы!" },
		{ id: 8, title: "Тэги", slug: "Тэги!" },
	];

	return (
		<>
			{catalog?.map((item) => (
				<CatalogItem
					key={item?.id}
					slug={item?.slug}
					title={item?.title}
					isFolder={true}
				/>
			))}
		</>
	);
}
