import React from "react";
import "./filter.css";
import clear from "../../../images/clear.svg";
import { useDispatch, useSelector } from "react-redux";
import { setFiltersBrand, setFiltersCategory, setFiltersColor, setFiltersModel, setFiltersService, setFiltersTag } from "../../../store/filtersSlice";

const Filter = ({ filter, role, filterCard, HandleResetFilters }) => {
	const dispatch = useDispatch();
	const filtersCategory = useSelector(
		(state) => state.filters.filtersCategory
	);
	const filtersModel = useSelector((state) => state.filters.filtersModel);
	const filtersBrand = useSelector((state) => state.filters.filtersBrand);
	const filtersService = useSelector((state) => state.filters.filtersService);
	const filtersColor = useSelector((state) => state.filters.filtersColor);
	const filtersTag = useSelector((state) => state.filters.filtersTag);
	function HandleReset() {
		dispatch(setFiltersCategory([]));
		dispatch(setFiltersBrand([]));
		dispatch(setFiltersModel([]));
		dispatch(setFiltersService([]));
		dispatch(setFiltersColor([]));
		dispatch(setFiltersTag([]));
		HandleResetFilters();
	}
	function handleDeleteFilter() {
		if(filtersCategory.includes(filter)) dispatch(setFiltersCategory(filtersCategory.filter((item) => item !== filter)));
		if(filtersBrand.includes(filter)) dispatch(setFiltersBrand(filtersBrand.filter((item) => item !== filter)));
		if(filtersModel.includes(filter)) dispatch(setFiltersModel(filtersModel.filter((item) => item !== filter)));
		if(filtersService.includes(filter)) dispatch(setFiltersService(filtersService.filter((item) => item !== filter)));
		if(filtersColor.includes(filter)) dispatch(setFiltersColor(filtersColor.filter((item) => item !== filter)));
		if(filtersTag.includes(filter)) dispatch(setFiltersTag(filtersTag.filter((item) => item !== filter)));
	}
	function HandleSetFilterTag() {
		dispatch(setFiltersTag([...filtersTag, filter]));
	}
	return (
		<>
			{role === "reset" ? (
				<button
					className="filter filter_clear"
					type="button"
					onClick={HandleReset}>
					{filter}
				</button>
			) : role === "card-tag" ? (
			<button
				className="filter filter_clear"
				type="button"
				onClick={HandleSetFilterTag}>
				{filter.title}
			</button>
			) : (
				<div className={filterCard ? "filter_card" : "filter"}>
					{filter?.title}
					<button type="button" className="filter_Btn" onClick={handleDeleteFilter}>
						<img src={clear} alt="clear" />
					</button>
				</div>
			)}
		</>
	);
};
export default Filter;
