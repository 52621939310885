import "./popup.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStateOpenPopup,
	changeStateOpenPopupActions,
	changeStateOpenPopupPostSuccess,
	changeStateOpenPopupReturn,
} from "../../store/popupSlice";
import closeOverlay from "../../images/popup/close-overlay-create-card.svg";
import copyErrorImage from "../../images/popup/error.svg";
import closeOverlayMobile from "../../images/popup/close-popup-mobile.svg";
import buttonDownloadCardImage from "../../images/tools/arrow_downward.svg";
import buttonInfoCardImage from "../../images/tools/learn_more.svg";
import { cardsApi } from "../api/cardInfoService";
import PopupCardInfo from "./popupCardInfo";
import Slider from "../slider/slider";
import Button from "../ui/buttons/button";
import { changeStatePreloader } from "../../store/toolkitSlice";
import { useGetDate } from "../../utils/useGetDate";
import FileSaver from "file-saver";
import ActionsBtns from "./actions/actionsBtns";
import { productsModerationApi } from "../api/products/moderationService";
import content_copy from "../../images/cards/content_copy.svg";

const Popup = () => {
	const dispatch = useDispatch();
	const [isCopying, setIsCopying] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);
	const [copyError, setCopyError] = useState("");
	const [date, setDate] = useState();
	const isOpenPopup = useSelector((state) => state.popup.openPopup);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const openPopupActions = useSelector(
		(state) => state.popup.openPopupActions
	);
	const statusCatalog = useSelector((state) => state.catalog.statusCatalog);
	const {
		data: cardPopupInfo,
		error: ErrorCardPopupInfo,
		isLoading: LoadingCardPopupInfo,
	} = cardsApi.useGetCardInfoQuery(cardInfo?.slug);
	const { cardDate, getCardDate } = useGetDate({ date });
	const [
		approveCard,
		{
			data: successApproveCard,
			error: ErrorApproveCard,
			isLoading: LoadingApproveCard,
		},
	] = productsModerationApi.useApproveProductsMutation();

	useEffect(() => {
		let dateCard = new Date(cardInfo.created_at);
		setDate(dateCard);
		setCopyError("");
	}, [cardInfo]);

	useEffect(() => {
		if (date) getCardDate(date);
	}, [date]);

	useEffect(() => {
		if (successApproveCard) {
			dispatch(changeStateOpenPopupPostSuccess(true));
		}
	}, [successApproveCard]);

	useEffect(() => {
		if (LoadingApproveCard === true || LoadingCardPopupInfo === true) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [LoadingApproveCard, LoadingCardPopupInfo]);

	async function copyImgToClipboard() {
		if (!navigator.clipboard || !window.ClipboardItem) {
			setCopyError("Clipboard API не поддерживается в этом браузере");
			return;
		}
		try {
			setIsCopying(true);
			const response = await fetch(cardInfo.preview, {
				mode: "cors",
				headers: {
					"Cache-Control": "no-cache",
				},
			});
			if (!response.ok) {
				throw new Error("Ошибка при загрузке изображения.");
			}
			const blob = await response.blob();
			const img = new Image();
			const reader = new FileReader();
			reader.onloadend = async function () {
				img.src = reader.result;
				img.onload = async function () {
					const canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0);
					canvas.toBlob(async function (pngBlob) {
						const clipboardItem = new ClipboardItem({
							"image/png": pngBlob,
						});
						try {
							await navigator.clipboard.write([clipboardItem]);
							setCopySuccess(true); // Показать уведомление об успехе
							setTimeout(() => setCopySuccess(false), 2000); // Скрыть уведомление через 2 секунды
						} catch (error) {
							setCopyError(
								"Ошибка при копировании изображения:",
								error
							);
							setCopyError(
								"Не удалось скопировать изображение! \n Не закрывайте вкладку, пока не завершится копирование"
							);
						} finally {
							setIsCopying(false); // Скрыть прелоудер
						}
					}, "image/png");
				};
			};

			reader.readAsDataURL(blob); // Читаем изображение как Data URL для использования на канвасе
		} catch (error) {
			setCopyError("Ошибка при копировании изображения.", error);
			setIsCopying(false); // Скрыть прелоудер
		}
	}

	function closePopup() {
		dispatch(changeStateOpenPopup(false));
		dispatch(changeStateOpenPopupActions(""));
	}

	function HandleSubmitRefuse() {
		closePopup();
		dispatch(changeStateOpenPopupReturn(true));
	}

	function HandleSubmitApprove(e) {
		e.preventDefault();
		approveCard(cardInfo?.slug);
		closePopup();
	}

	function HandleDownload() {
		if (cardPopupInfo.file) {
			FileSaver.saveAs(cardPopupInfo.file, `${cardPopupInfo.title}.png`);
		}
	}

	function handleOpenPopupActions() {
		if (openPopupActions !== "") {
			dispatch(changeStateOpenPopupActions(""));
		} else {
			dispatch(changeStateOpenPopupActions(cardInfo?.slug));
		}
	}

	return (
		isOpenPopup && (
			<div className="popup">
				<button
					className="popup__overlay"
					type="button"
					onClick={closePopup}
				/>
				<div className="popup__card-info">
					{copyError && (
						<div className="popup__copy">
							<img
								onClick={() => setCopyError("")}
								src={
									pageWidth > 1100
										? closeOverlay
										: closeOverlayMobile
								}
								alt="close-overlay"
								className="popup__close-overlay"
							/>
							<img
								src={copyErrorImage}
								alt="copyError"
								className="popup__copy-error-image"
							/>
							<h4>{copyError}</h4>
						</div>
					)}
					<img
						onClick={closePopup}
						src={
							pageWidth > 1100 ? closeOverlay : closeOverlayMobile
						}
						alt="close-overlay"
						className="popup__close-overlay"
					/>
					{cardInfo && <Slider cardImages={cardInfo.preview} />}
					<div className="popup__card-tools">
						<div className="popup__card-name">
							<div className="popup__card-author">
								{cardInfo.author.full_name} &#183; {cardDate}
							</div>
						</div>
						<h3 className="popup__card-name">{cardInfo.title}</h3>
						{statusCatalog !== "action" && (
							<div className="popup__buttons">
								<button
									className="popup__button"
									type="button"
									onClick={copyImgToClipboard}>
									{isCopying
										? "Копирование..."
										: copySuccess
										? "Скопировано"
										: "Скопировать в буфер"}
								</button>
								<button
									className="popup__tool"
									type="button"
									onClick={HandleDownload}>
									<img
										src={buttonDownloadCardImage}
										alt="buttonDownloadCardImage"
									/>
								</button>
								<button
									className="popup__tool"
									type="button"
									onClick={handleOpenPopupActions}>
									<img
										src={buttonInfoCardImage}
										alt="buttonDownloadCardImage"
									/>
								</button>
								{openPopupActions === cardInfo?.slug && (
									<ActionsBtns />
								)}
							</div>
						)}
						<PopupCardInfo cardPopupInfo={cardPopupInfo} />
						{statusCatalog === "action" && (
							<div className="buttons">
								<button
									className="button__moderation"
									type="button"
									onClick={HandleSubmitRefuse}>
									<Button
										textButton="Отклонить"
										type={"secondary"}
									/>
								</button>
								<button
									className="button__moderation"
									type="submit"
									onClick={HandleSubmitApprove}>
									<Button
										textButton="Опубликовать"
										type={"primary"}
									/>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	);
};
export default Popup;
