import React, { useEffect, useState } from "react";
import "./header.css";
import logo from "../../images/mainImages/logo.svg";
import search_icon from "../../images/tools/search_icon.svg";
import all_filters from "../../images/all-filters.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStateOpenPopupCreateCard,
	setCardInfo,
} from "../../store/popupSlice";
import Button from "../ui/buttons/button";
import { changeStatePreloader } from "../../store/toolkitSlice";
import { productsApi } from "../api/productsService";
import {
	setFileType,
	setFilterChecked,
	setFiltersSearch,
} from "../../store/filtersSlice";
import { changeStatusCatalog } from "../../store/catalogSlice";
import { userApi } from "../api/userService";
import { changeUserData } from "../../store/authSlice";

const Header = () => {
	const [searchText, setSearchText] = useState("");
	const dispatch = useDispatch();
	const authorized = useSelector((state) => state.auth.authorized);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const { data: user } = userApi.useGetUserQuery();
	const node = document.getElementsByClassName("header__searchInput")[0];

	const {
		data: productsOnAction,
		isLoading: isOnAction,
		refetch,
	} = productsApi.useGetOnActionProductsQuery();
	useEffect(() => {
		if (isOnAction === true) dispatch(changeStatePreloader(true));
		else dispatch(changeStatePreloader(false));
	}, [isOnAction]);

	function handleChangeInputSearch(e) {
		setSearchText(e.target.value);
	}

	function handleOpenPopupCreateCard() {
		const promise = new Promise((resolve, reject) => {
			resolve(dispatch(setCardInfo(null)));
		});
		promise.then(() => dispatch(changeStateOpenPopupCreateCard(true)));
	}

	useEffect(() => {
		refetch();
		if (user) {
			dispatch(changeUserData(user));
		}
	}, [user]);

	function handleSearchClick() {
		dispatch(setFiltersSearch(`&search=${searchText}`));
	}

	function handleChangePage() {
		dispatch(setFileType("Image"));
		dispatch(setFilterChecked(false));
	}

	function handleClickLogo() {
		dispatch(changeStatusCatalog("main"));
		setSearchText("");
		dispatch(setFiltersSearch(""));
	}

	// Enter поиск по нажатию на кнопку
	useEffect(() => {
		if (node) {
			node.addEventListener("keyup", function (event) {
				if (event.key === "Enter") {
					handleSearchClick();
					console.log("handleSearchClick");
				}
			});
		}
	}, [node])

	return (
		<header className="header">
			{authorized ? (
				<div className="header__container">
					<Link to="/main">
						<img
							src={logo}
							alt="logo"
							className="header__logo"
							onClick={handleClickLogo}
						/>
					</Link>
					{pageWidth < 940 ? (
						<div className="header__mobile">
							<div className="header__search">
								<img
									src={search_icon}
									alt="search_icon"
									className="header__search_icon"
								/>
								<input
									value={searchText || ""}
									onChange={handleChangeInputSearch}
									className="header__searchInput"
									placeholder="Название или артикул"
								/>
							</div>
							<button className="header__filters">
								<img
									src={all_filters}
									alt="filters_mobile_icon"
								/>
							</button>
						</div>
					) : (
						<>
							<div className="header__search">
								<button type="button"
									className="header__searchButton"
									onClick={handleSearchClick}>
									<img
										src={search_icon}
										alt="search_icon"
										className="header__search_icon"
									/>
								</button>
								<input
									value={searchText}
									onChange={(e) =>
										setSearchText(e.target.value)
									}
									className="header__searchInput"
									placeholder="Название или артикул"
								/>
							</div>
							<button
								type="button"
								className="header__notificationContainer"
								onClick={handleChangePage}>
								<Link
									className={
										productsOnAction?.length
											? "header__profile productsOnAction"
											: "header__profile"
									}
									to="/account">
									{productsOnAction?.length !== 0 && (
										<div className="header__countOnAction">
											{productsOnAction?.length}
										</div>
									)}
									{user ? (
										<p className="header__profile-text">
											{user?.full_name.indexOf(" ") > -1
												? user?.full_name
													.match(/[А-ЯA-Z]/g)
													.join("")
												: Array.from(
													user?.full_name
												)[0]}
										</p>
									) : (
										<></>
									)}
								</Link>
								{pageWidth > 1139 ? (
									<p className="header__profileName">
										{user?.full_name}
									</p>
								) : (
									<></>
								)}
							</button>
							<button
								className="buttonHeader"
								type="button"
								onClick={handleOpenPopupCreateCard}>
								<Button
									textButton="Добавить"
									type={"primary"}
								/>
							</button>
						</>
					)}
				</div>
			) : (
				<>
					<img src={logo} alt="logo" className="header__logo" />
				</>
			)}
		</header>
	);
};

export default Header;
