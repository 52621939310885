import "./auth.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAuthStatus } from "../../store/authSlice";
import changePassword from "../../images/profile/changePassword.svg";

const Done = () => {
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const dispatch = useDispatch();
	return (
		<div className="auth">
			<div className="container">
				<img
					src={changePassword}
					alt="changePassword"
					className="auth__change"
				/>
				<div className="success">
					{pageWidth > 450 ? (
						<>
							<h2 className="auth__text">Успешно!</h2>
							<h2 className="auth__text">Вы поменяли пароль</h2>
						</>
					) : (
						<>
							<h3 className="auth__text">Успешно!</h3>
							<h3 className="auth__text">Вы поменяли пароль</h3>
						</>
					)}
				</div>
				<button
					className="auth__btn"
					type="submit"
					onClick={() => dispatch(changeAuthStatus("login"))}>
					<h4 className="auth__btn-text">Войти</h4>
				</button>
			</div>
		</div>
	);
};
export default Done;
