import React from "react";
import "./catalogSettings.css";
import { useDispatch, useSelector } from "react-redux";
import add from "../../images/profile/add.svg";
import arrow_back from "../../images/profile/arrow_back.svg";
import { setOpenFolder } from "../../store/accountSettingsSlice";
import CatalogInfo from "./catalogInfo";
import { changeStateOpenPopupAdd } from "../../store/popupSlice";

const CatalogSettings = () => {
	const dispatch = useDispatch();
	const openFolder = useSelector((state) => state.account.openFolder);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	return (
		<>
			<div className="catalog__settings">
				<div className="catalog__settings_title">
					{openFolder === "" ? (
						<></>
					) : (
						<img
							src={arrow_back}
							alt="arrow back"
							className="catalog__arrow_back"
							onClick={() => dispatch(setOpenFolder(""))}
						/>
					)}
					<h1 className="catalog__settings-title">
						{openFolder === "" ? "Настройки каталога" : openFolder}
					</h1>
				</div>
				{openFolder !== "" ? (
					<button
						className="add__folder"
						type="button"
						onClick={() => dispatch(changeStateOpenPopupAdd(true))}>
						<img src={add} alt="add folder" />
						{pageWidth > 900 ? <p>Создать</p> : <></>}
					</button>
				) : (
					<></>
				)}
			</div>
			<CatalogInfo />
		</>
	);
};

export default CatalogSettings;
