import { authApi } from "./authService";

export const categoriesApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addCategories: builder.mutation({
			query: (title) => ({
				url: "/categories/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Categories"],
		}),
		putCategories: builder.mutation({
			query: (data) => ({
				url: `/categories/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
				},
			}),
			invalidatesTags: ["Categories"],
		}),
		deleteCategories: builder.mutation({
			query: (slug) => ({
				url: `/categories/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Categories"],
		}),
		getCategories: builder.query({
			query: () => ({ url: "/categories/" }),
			providesTags: ["Categories"],
		}),
	}),
});
