import React from "react";
import "./preloader.css";
import { useSelector } from "react-redux";

export default function Preloader() {
	const preloaderActive = useSelector((state) => state.toolkit.preloader);
	return (
		preloaderActive && (
			<div className="preloader">
				<div className="preloader__overlay" />
				<div className="preloader__spinner"></div>
				<p>Загрузка...</p>
			</div>
		)
	);
}
