import React from "react";

export default function ViewAgenda({ twoCardsOnRow }) {
	return (
		<div>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<mask
					id="mask0_2406_5258"
					style={{ mask: "alpha" }}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="20"
					height="20">
					<rect width="20" height="20" fill="#D9D9D9" />
				</mask>
				<g mask="url(#mask0_2406_5258)">
					<path
						d="M4.16667 9.16667C3.70833 9.16667 3.31597 9.00347 2.98958 8.67708C2.66319 8.35069 2.5 7.95833 2.5 7.5V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V7.5C17.5 7.95833 17.3368 8.35069 17.0104 8.67708C16.684 9.00347 16.2917 9.16667 15.8333 9.16667H4.16667ZM4.16667 7.5H15.8333V4.16667H4.16667V7.5ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V12.5C2.5 12.0417 2.66319 11.6493 2.98958 11.3229C3.31597 10.9965 3.70833 10.8333 4.16667 10.8333H15.8333C16.2917 10.8333 16.684 10.9965 17.0104 11.3229C17.3368 11.6493 17.5 12.0417 17.5 12.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V12.5H4.16667V15.8333Z"
						fill={!twoCardsOnRow ? "#263238" : "#60708B"}
					/>
				</g>
			</svg>
		</div>
	);
}
