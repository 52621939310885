import React, { useEffect } from "react";
import "./change.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/buttons/button";
import { changeStateOpenPopupChangeName } from "../../../store/popupSlice";
import { useFormAndValidationUserName } from "../../../utils/useFormAndValidationUserName";
import { userApi } from "../../api/userService";
import { changeStatePreloader } from "../../../store/toolkitSlice";

export default function ChangeName() {
	const dispatch = useDispatch();
	const openPopupChangeName = useSelector(
		(state) => state.popup.openPopupChangeName
	);
	const { values, handleChange, errors, isValid, resetErrors } =
		useFormAndValidationUserName({});

	const { data: user, isLoading: isGettingUser } = userApi.useGetUserQuery();

	const [
		putUser,
		{ data: success, error: Error, isLoading: isFetchingUser },
	] = userApi.usePutUserMutation();

	useEffect(() => {
		if (isFetchingUser == true && isGettingUser == true) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [isFetchingUser, isGettingUser]);

	async function HandleSubmitChangeName(e) {
		e.preventDefault();
		if (user.id) {
			await putUser({
				id: user.id,
				email: user.email,
				picture: user.picture,
				userName: values.userName,
			});
		}
		dispatch(changeStateOpenPopupChangeName(false));
	}

	useEffect(() => {
		if (openPopupChangeName) {
			console.log(Error);
		}
	}, [Error]);
	useEffect(() => {
		if (openPopupChangeName) {
			console.log("success change name: " + success);
		}
	}, [success]);

	return (
		openPopupChangeName && (
			<form className="popupChangeName">
				<button
					className="overlay"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupChangeName(false))
					}></button>
				<div className="change">
					<h2>Имя и фамилия</h2>
					<input
						className="input"
						id="userName"
						name="userName"
						type="text"
						value={values.userName || ""}
						onChange={handleChange}
						required
					/>
					<div className="newCardButtons">
						<button
							className="buttonNewCard"
							type="button"
							onClick={() =>
								dispatch(changeStateOpenPopupChangeName(false))
							}>
							<Button textButton="Отмена" type={"secondary"} />
						</button>
						<button
							className="buttonNewCard"
							type="submit"
							onClick={HandleSubmitChangeName}>
							<Button textButton="Сохранить" type={"primary"} />
						</button>
					</div>
				</div>
			</form>
		)
	);
}
