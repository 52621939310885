import React from "react";
import folder from "../../images/profile/folder.svg";
import actions from "../../images/profile/actions.svg";
import "./catalogItem.css";
import { useDispatch, useSelector } from "react-redux";
import { setOpenFolder } from "../../store/accountSettingsSlice";
import { changeStateOpenPopupActions } from "../../store/popupSlice";
import { changeBrandForModelsSlug, changeSlug } from "../../store/toolkitSlice";
import ActionsBtns from "../popup/actions/actionsBtns";

const CatalogItem = ({ slug, title, isFolder, brand, color }) => {
	const dispatch = useDispatch();
	const openFolder = useSelector((state) => state.account.openFolder);
	function HandlePopupActionsClick() {
		if(openPopupActions === "") {
			dispatch(changeStateOpenPopupActions(slug));
			dispatch(changeSlug(slug));
		} else {
			dispatch(changeStateOpenPopupActions(""));
		}
	}
	const openPopupActions = useSelector(
		(state) => state.popup.openPopupActions
	);
	function handleClickFolder() {
		if (openFolder === "Марка авто") {
			if (brand) {
				dispatch(changeBrandForModelsSlug(brand));
				dispatch(setOpenFolder("Модель авто"));
			}
		} else dispatch(setOpenFolder(title));
	}

	return (
		<li className="catalog__folders">
			<button
				className="folder-block"
				type="button"
				disabled={!isFolder}
				onClick={handleClickFolder}>
				{color && <div
					className="catalog__color"
					style={{
						backgroundColor: `${color}`,
					}}
				/>}
				{isFolder ? <img src={folder} alt="folder" /> : <></>}
				<p>{title}</p>
			</button>
			{openFolder === "" ? (
				<></>
			) : (
				<button
					className="actions"
					type="button"
					onClick={HandlePopupActionsClick}>
					<img src={actions} alt="actions" />
				</button>
			)}
			{openPopupActions === slug && <ActionsBtns />}
		</li>
	);
};

export default CatalogItem;
