import React, { useState } from "react";
import "./dropdown.css";
import "./select.css";
import arrow_drop_down from "../../../images/dropdown/arrow_drop_down.svg";
import arrow_drop_up from "../../../images/dropdown/arrow_drop_up.svg";
import select_icon from "../../../images/mobile/select-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { dropdownSort } from "../../../store/dropdown";
import Select from "react-dropdown-select";
import { setFiltersSorter } from "../../../store/filtersSlice";

export default function Sorter() {
	const dispatch = useDispatch();
	const sort = dropdownSort;
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const [values, setValues] = useState([{ id: 1, text: "Сначала новые" }]);
	const handleChange = (values) => {
		setValues(values);
		switch (values[0].id) {
			case 1:
				dispatch(setFiltersSorter("&ordering=-published_at"));
				break;
			case 2:
				dispatch(setFiltersSorter("&ordering=published_at"));
				break;
		}
	};
	return (
		<Select
			className={`select ${pageWidth > 768 ? "" : "select_mobile"}`}
			options={sort}
			valueField="id"
			labelField="text"
			values={values}
			itemRenderer={({ item, methods }) => (
				<div className="select__element">
					{item.disabled ? (
						<div className="element">{item.text}</div>
					) : (
						<div
							onClick={() => methods.addItem(item)}
							className="element">
							<input
								className="custom-checkbox"
								onChange={() => methods.addItem(item)}
								type="checkbox"
								checked={methods.isSelected(item)}
								id="checked"
								name="checked"
							/>
							<p htmlFor="checked">{item.text}</p>
						</div>
					)}
				</div>
			)}
			onChange={(values) => handleChange(values)}
			closeOnSelect
			closeOnClickInput
			searchable="false"
			dropdownHandleRenderer={({ state }) => (
				<span>
					{pageWidth > 768 ? (
						<img
							src={
								state.dropdown ? arrow_drop_up : arrow_drop_down
							}
							alt="dropdown__item_select_icon"
						/>
					) : (
						<img
							src={select_icon}
							alt="dropdown__item_select_icon"
						/>
					)}
				</span>
			)}
		/>
	);
}
