import "./auth.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAuthStatus } from "../../store/authSlice";

const Confirm = () => {
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const dispatch = useDispatch();
	const onSubmit = (e) => {
		e.preventDefault();
		console.log("submit");
		dispatch(changeAuthStatus("new"));
	};
	return (
		<div className="auth">
			<div className="container">
				{pageWidth > 450 ? (
					<h2 className="auth__text">Подтверждение</h2>
				) : (
					<h3 className="auth__text">Подтверждение</h3>
				)}
				<span className="placeholder">
					Отправили письмо на почту с инструкцией как восстановить
					доступ к аккаунту
				</span>
				<div className="inputs">
					<div className="input__auth">
						<input
							className="email"
							id="email"
							name="email"
							type="email"
							// value={values.email || ""}
							// onChange={handleChange}
							required
						/>
						<span className="placeholder">E-mail</span>
						{/* <span
							className={`login__error ${
								!isValid ? "login__error_active" : ""
							}`}>
							{errors.email}
						</span> */}
					</div>
				</div>
				<button className="auth__btn" type="submit" onClick={onSubmit}>
					<h4 className="auth__btn-text">Отправить повторно</h4>
				</button>
				<span>Повторная отправка через 1:59</span>
			</div>
		</div>
	);
};
export default Confirm;
