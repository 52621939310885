import { createSlice } from "@reduxjs/toolkit";

const catalogSlice = createSlice({
	name: "catalogSlice",
	initialState: {
		multiSelect: false,
		twoCardsOnRow: false,
		statusCatalog: "main",
		selectedCards: [],
	},
	reducers: {
		setMultiSelect(state, action) {
			state.multiSelect = action.payload;
		},
		setTwoCardsOnRow(state, action) {
			state.twoCardsOnRow = action.payload;
		},
		changeStatusCatalog(state, action) {
			state.statusCatalog = action.payload;
		},
		changeSelectedCard(state, action) {
			state.selectedCards = action.payload;
		},
	},
});

export default catalogSlice.reducer;
export const { setMultiSelect, setTwoCardsOnRow, changeStatusCatalog, changeSelectedCard } =
	catalogSlice.actions;
