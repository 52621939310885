import { authApi } from "./authService";

export const colorsApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addColors: builder.mutation({
			query: (title) => ({
				url: "/colors/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Colors"],
		}),
		putColors: builder.mutation({
			query: (data) => ({
				url: `/colors/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
				},
			}),
			invalidatesTags: ["Colors"],
		}),
		deleteColors: builder.mutation({
			query: (slug) => ({
				url: `/colors/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Colors"],
		}),
		getColors: builder.query({
			query: () => ({ url: "/colors/" }),
			providesTags: ["Colors"],
		}),
	}),
});
