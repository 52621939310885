import "./slider.css";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

const Slider = ({ cardImages }) => {
	const fileType = useSelector((state) => state.filters.fileType);
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const [play, setPlay] = useState(false);
	const videoStyle = {
		borderTopLeftRadius: "16px",
		borderBottomLeftRadius: "16px",
		objectFit: "cover",
		overflow: "hidden",
		height: "100%",
	};
	function playVideoHandler() {
		if (fileType === "Video") {
			setPlay(true);
		}
	}
	function stopVideoHandler() {
		if (fileType === "Video") {
			setPlay(false);
		}
	}
	return (
		<div
			className="slider"
			onMouseEnter={playVideoHandler}
			onMouseLeave={stopVideoHandler}>
			{fileType === "Video" ? (
				<ReactPlayer
					url={cardInfo.file}
					width="100%"
					height="100%"
					style={videoStyle}
					playing={play}
				/>
			) : (
				<img src={cardImages} alt="imagePopup" className="image" />
			)}
		</div>
	);
};

export default Slider;
