import { useState } from "react";

export function useGetDate({ date }) {
	const [cardDate, setCardDate] = useState();
	let result = "";
	function getCardDate() {
		result = date?.getDate() + " ";
		switch (date?.getMonth() + 1) {
			case 1:
				result = result + "янв ";
				break;
			case 2:
				result = result + "фев ";
				break;
			case 3:
				result = result + "мар ";
				break;
			case 4:
				result = result + "апр ";
				break;
			case 5:
				result = result + "май ";
				break;
			case 6:
				result = result + "июн ";
				break;
			case 7:
				result = result + "июл ";
				break;
			case 8:
				result = result + "авг ";
				break;
			case 9:
				result = result + "сен ";
				break;
			case 10:
				result = result + "окт ";
				break;
			case 11:
				result = result + "ноя ";
				break;
			case 12:
				result = result + "дек ";
				break;
			default:
				break;
		}
		result = result + date?.getFullYear();
		setCardDate(result);
	}
	return { cardDate, getCardDate };
}
