import React from "react";
import "./myProducts.css";
import { userApi } from "../../../components/api/userService";
import Card from "../../../components/card/card";

export default function MyProducts({ id }) {
	const { data: products } = userApi.useGetUserProductsQuery(id);

	return (
		<div className="my_products">
			{products?.map((card) => (
				<Card key={card.id} cardInfo={card} />
			))}
		</div>
	);
}
