import React, { useEffect } from "react";
import "./change.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/buttons/button";
import { changeStateOpenPopupChangeNameCategory } from "../../../store/popupSlice";
import { useFormAndValidationUserName } from "../../../utils/useFormAndValidationUserName";
import { brandsApi } from "../../api/brandsService";
import { categoriesApi } from "../../api/categoriesService";
import { modelsApi } from "../../api/modelsService";
import { colorsApi } from "../../api/colorsService";
import { servicesApi } from "../../api/servicesService";
import { tagsApi } from "../../api/tagsService";
import { changeStatePreloader } from "../../../store/toolkitSlice";

export default function ChangeCategory() {
	const dispatch = useDispatch();
	const openPopupChangeNameCategory = useSelector(
		(state) => state.popup.openPopupChangeNameCategory
	);
	const { values, handleChange, errors, isValid, resetErrors } =
		useFormAndValidationUserName({});
	const openFolder = useSelector((state) => state.account.openFolder);
	const slug = useSelector((state) => state.toolkit.slug);
	const [
		putBrands,
		{
			data: successPutBrands,
			error: ErrorPutBrands,
			isLoading: LoadingPutBrands,
		},
	] = brandsApi.usePutBrandsMutation();
	const [
		putModels,
		{
			data: successPutModels,
			error: ErrorPutModels,
			isLoading: LoadingPutModels,
		},
	] = modelsApi.usePutModelsMutation();
	const [
		putCategories,
		{
			data: successPutCategories,
			error: ErrorPutCategories,
			isLoading: LoadingPutCategories,
		},
	] = categoriesApi.usePutCategoriesMutation();
	const [
		putColors,
		{
			data: successPutColors,
			error: ErrorPutColors,
			isLoading: LoadingPutColors,
		},
	] = colorsApi.usePutColorsMutation();
	const [
		putServices,
		{
			data: successPutServices,
			error: ErrorPutServices,
			isLoading: LoadingPutServices,
		},
	] = servicesApi.usePutServicesMutation();
	const [
		putTags,
		{
			data: successPutTags,
			error: ErrorPutTags,
			isLoading: LoadingPutTags,
		},
	] = tagsApi.usePutTagsMutation();

	useEffect(() => {
		if (
			LoadingPutBrands == true &&
			LoadingPutCategories == true &&
			LoadingPutModels == true &&
			LoadingPutColors == true &&
			LoadingPutServices == true &&
			LoadingPutTags == true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		LoadingPutBrands,
		LoadingPutCategories,
		LoadingPutModels,
		LoadingPutColors,
		LoadingPutServices,
		LoadingPutTags,
	]);

	async function HandleSubmitChangeCategory(e) {
		e.preventDefault();
		if (slug) {
			switch (openFolder) {
				case "Марка авто":
					await putBrands({ title: values.categoryName, slug: slug });
					break;
				case "Модель авто":
					// await putModels({ title: values.categoryName, slug: slug }); // еще необходимо бренд передавать
					break;
				case "Категории":
					console.log(values);
					await putCategories({
						title: values.categoryName,
						slug: slug,
					});
					break;
				case "Цвет":
					await putColors({
						title: values.categoryName,
						slug: slug,
					});
					break;
				case "Услуги":
					await putServices({
						title: values.categoryName,
						slug: slug,
					});
					break;
				case "Продукты":
					break;
				case "Тэги":
					await putTags({
						title: values.categoryName,
						slug: slug,
					});
					break;

				default:
					break;
			}
		}
		dispatch(changeStateOpenPopupChangeNameCategory(false));
	}

	return (
		openPopupChangeNameCategory && (
			<form className="popupChangeName">
				<button
					className="overlay"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupChangeNameCategory(false))
					}></button>
				<div className="change">
					<h2>Введите новое название</h2>
					<input
						className="input"
						id="categoryName"
						name="categoryName"
						type="text"
						value={values.categoryName || ""}
						onChange={handleChange}
						required
					/>
					<div className="newCardButtons">
						<button
							className="buttonNewCard"
							type="button"
							onClick={() =>
								dispatch(
									changeStateOpenPopupChangeNameCategory(
										false
									)
								)
							}>
							<Button textButton="Отмена" type={"secondary"} />
						</button>
						<button
							className="buttonNewCard"
							type="submit"
							onClick={HandleSubmitChangeCategory}>
							<Button textButton="Сохранить" type={"primary"} />
						</button>
					</div>
				</div>
			</form>
		)
	);
}
