import { authApi } from "./authService";

export const brandsApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addBrands: builder.mutation({
			query: (title) => ({
				url: "/brands/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Brands"],
		}),
		putBrands: builder.mutation({
			query: (data) => ({
				url: `/brands/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
				},
			}),
			invalidatesTags: ["Brands"],
		}),
		deleteBrands: builder.mutation({
			query: (slug) => ({
				url: `/brands/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Brands"],
		}),
		getBrands: builder.query({
			query: () => ({ url: "/brands/" }),
			providesTags: ["Brands"],
		}),
	}),
});
