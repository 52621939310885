import { createSlice } from "@reduxjs/toolkit";

const accountSettingsSlice = createSlice({
	name: "accountSettingsSlice",
	initialState: {
		openFolder: "",
	},
	reducers: {
		setOpenFolder(state, action) {
			state.openFolder = action.payload;
		},
	},
});

export default accountSettingsSlice.reducer;
export const { setOpenFolder } = accountSettingsSlice.actions;
