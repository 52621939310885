import React, { useEffect, useState } from "react";
import Dropdown from "../ui/dropdown/dropdown";
import all_filters from "../../images/all-filters.svg";
import "./filters.css";
import { useDispatch, useSelector } from "react-redux";
import { brandsApi } from "../api/brandsService";
import { categoriesApi } from "../api/categoriesService";
import { modelsApi } from "../api/modelsService";
import { colorsApi } from "../api/colorsService";
import { servicesApi } from "../api/servicesService";
import { changeStateOpenAllFiltersPopup } from "../../store/popupSlice";
import { changeStatePreloader } from "../../store/toolkitSlice";
const Filters = () => {
	const dispatch = useDispatch();
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const filtersBrand = useSelector(
		(state) => state.filters.filtersBrand[0]?.slug
	);
	const { data: brands, isLoading: isFetchingBrands } =
		brandsApi.useGetBrandsQuery();
	const { data: categories, isLoading: isFetchingCategories } =
		categoriesApi.useGetCategoriesQuery();
	const { data: brands_models, isLoading: isFetchingModels } =
		modelsApi.useGetModelsQuery(
			filtersBrand ? `?brand=${filtersBrand}` : ""
		);
	const { data: colors, isLoading: isFetchingColors } =
		colorsApi.useGetColorsQuery();
	const { data: services, isLoading: isFetchingServices } =
		servicesApi.useGetServicesQuery();

	useEffect(() => {
		if (
			isFetchingBrands === true ||
			isFetchingCategories === true ||
			isFetchingModels === true ||
			isFetchingColors === true ||
			isFetchingServices === true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		isFetchingBrands,
		isFetchingCategories,
		isFetchingModels,
		isFetchingColors,
		isFetchingServices,
	]);

	return (
		pageWidth > 767 && (
			<div className="filters">
				<div className="filters__container">
					<Dropdown text="Марка авто" dropdownData={brands} />
					<Dropdown text="Модель авто" dropdownData={brands_models} />
					{pageWidth > 939 ? (
						<Dropdown text="Категории" dropdownData={categories} />
					) : (
						<></>
					)}
					{pageWidth > 1139 ? (
						<Dropdown text="Услуги" dropdownData={services} />
					) : (
						<></>
					)}
					{pageWidth > 1439 ? (
						<Dropdown text="Цвет" dropdownData={colors} />
					) : (
						<></>
					)}
					<button
						className="allFilters"
						type="button"
						onClick={() =>
							dispatch(changeStateOpenAllFiltersPopup(true))
						}>
						<img src={all_filters} alt="all filters" />
						<p className="allFilters-text">Все фильтры</p>
					</button>
				</div>
			</div>
		)
	);
};

export default Filters;
