import React, { useEffect, useState } from "react";
import "./dropdown.css";
import { useSelector } from "react-redux";
import { useDropdownValues } from "../../../utils/dropdownValues";
import arrow_drop_down from "../../../images/dropdown/arrow_drop_down.svg";
import arrow_drop_up from "../../../images/dropdown/arrow_drop_up.svg";
import Select from "react-dropdown-select";

export default function AllFiltersDropdown({ text, dropdownData }) {
	const [values, setValues] = useState([]);
	const filtersCategory = useSelector(
		(state) => state.filters.filtersCategory
	);
	const filtersModel = useSelector((state) => state.filters.filtersModel);
	const filtersBrand = useSelector((state) => state.filters.filtersBrand);
	const filtersService = useSelector((state) => state.filters.filtersService);
	const filtersColor = useSelector((state) => state.filters.filtersColor);
	const filtersTag = useSelector((state) => state.filters.filtersTag);
	const { count, handleChangeValue } = useDropdownValues({ values, text, filtersBrand });
	useEffect(() => {
		switch (text) {
			case "Категории":
				setValues(filtersCategory);
				break;
			case "Марка авто":
				setValues(filtersBrand);
				break;
			case "Модель авто":
				setValues(filtersModel);
				break;
			case "Услуги":
				setValues(filtersService);
				break;
			case "Цвет":
				setValues(filtersColor);
				break;
			case "Тэги":
				setValues(filtersTag);
				break;
			default:
				break;
		}
	}, [
		filtersColor,
		filtersCategory,
		filtersService,
		filtersModel,
		filtersBrand,
		filtersTag,
	]);
	useEffect(() => {
		handleChangeValue({ values, text });
	}, [values]);

	const handleChange = (values) => {
		if (filtersBrand[0] === values[0]) {
			setValues([]);
		} else {
			setValues(values);
		}
	};

	return (
		<div className="allFiltersDropdown__dropdown">
			{/* <div className="allFiltersDropdown__dropdown" style={ {marginBottom: 100 }}></div> */}
			<Select
				className="allFiltersDropdown"
				options={dropdownData}
				multi={text === "Марка авто" ? false : true}
				disabled={
					text === "Модель авто" && filtersBrand.length === 0
						? true
						: false
				}
				valueField="id"
				labelField="title"
				values={values}
				itemRenderer={({ item, methods }) => (
					<div className="select__element">
						{item.disabled ? (
							<div className="element">{item.title}</div>
						) : (
							<div
								onClick={() => methods.addItem(item)}
								className="element">
								<input
									className="custom-checkbox"
									onChange={() => methods.addItem(item)}
									type="checkbox"
									checked={methods.isSelected(item)}
									id="checked"
									name="checked"
								/>
								<div
									className="element__text"
									htmlFor="checked">
									{text === "Цвет" ? (
										<div
											className="element__color"
											style={{
												backgroundColor: `${item.hex_code}`,
											}}
										/>
									) : (
										<></>
									)}
									<p>{item.title}</p>
								</div>
							</div>
						)}
					</div>
				)}
				onChange={(values) => handleChange(values)}
				closeOnSelect
				closeOnClickInput
				contentRenderer={() => (
					<div className="placeholderFilters">
						{text}{" "}
						{count === 0 ? (
							<></>
						) : (
							<div className="countFilters">{count}</div>
						)}
					</div>
				)}
				dropdownHandleRenderer={({ state }) => (
					<span className="dropdown__arrow">
						<img
							src={
								state.dropdown ? arrow_drop_up : arrow_drop_down
							}
							alt="dropdown__item_select_icon"
						/>
					</span>
				)}
			/>
		</div>
	);
}
