import React, { useEffect, useState } from "react";
import CreateCardInput from "../../ui/inputs/createCardInput";
import InputFiles from "../../ui/inputs/inputFiles";
import { categoriesApi } from "../../api/categoriesService";
import { modelsApi } from "../../api/modelsService";
import { colorsApi } from "../../api/colorsService";
import { tagsApi } from "../../api/tagsService";
import { servicesApi } from "../../api/servicesService";
import { brandsApi } from "../../api/brandsService";
import ReturnText from "../../ui/inputs/returnText";
import { productsApi } from "../../api/productsService";

export default function CreateCardInputs({
	onSetFile,
	cardInfo,
	modelsError,
	categoryError,
	filesError,
}) {
	const { data: categories } = categoriesApi.useGetCategoriesQuery();
	const { data: brands } = brandsApi.useGetBrandsQuery();
	const { data: brands_models } = modelsApi.useGetModelsQuery(
		brands ? `?brand=${brands[0]?.slug}` : ""
	);
	const { data: colors } = colorsApi.useGetColorsQuery();
	const { data: services } = servicesApi.useGetServicesQuery();
	const { data: tags } = tagsApi.useGetTagsQuery();
	const { data: product } = productsApi.useGetCurrentProductQuery(
		cardInfo?.slug
	);
	const [inputbrand, setInputbrand] = useState();

	useEffect(() => {
		if (product && brands) {
			function isBrand(brand) {
				return brand.slug === product.brand_models[0].brand;
			}
			setInputbrand([brands.find(isBrand)]);
		}
	}, [product]);

	return (
		<div className="createCardPopup__inputs">
			{cardInfo?.status === "ON_REWORK" && (
				<ReturnText cardInfo={cardInfo} />
			)}
			<div className="createCardPopup__inputs">
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">Марка авто</h4>
					<CreateCardInput
						placeholder="Выберите марку"
						dropdownData={brands}
						currentProduct={inputbrand}
					/>
				</div>
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">
						Модель авто
					</h4>
					<div className="newCardClear__input_block">
						<CreateCardInput
							placeholder="Выберите модель"
							dropdownData={brands_models}
							currentProduct={product?.brand_models}
						/>
						{modelsError && (
							<span className="newCardClear__input_error">
								Это поле не может быть пустым
							</span>
						)}
					</div>
				</div>
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">Категория</h4>
					<div className="newCardClear__input_block">
						<CreateCardInput
							placeholder="Выберите категорию"
							dropdownData={categories}
							currentProduct={product?.categories}
						/>
						{categoryError && (
							<span className="newCardClear__input_error">
								Это поле не может быть пустым
							</span>
						)}
					</div>
				</div>
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">Цвет</h4>
					<CreateCardInput
						placeholder="Введите название цвета"
						dropdownData={colors}
						currentProduct={product?.colors}
					/>
				</div>
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">Теги</h4>
					<CreateCardInput
						placeholder="Введите тег"
						dropdownData={tags}
						currentProduct={product?.tags}
					/>
				</div>
				<div className="createCardPopup__inputs-containers">
					<h4 className="newCardClear__input-box-text">Услуги</h4>
					<CreateCardInput
						placeholder="Выберите услугу"
						dropdownData={services}
						currentProduct={product?.services}
					/>
				</div>
			</div>
			<InputFiles onSetFile={onSetFile} filesError={filesError} />
		</div>
	);
}
