import { authApi } from "./authService";

export const productsApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query({
			query: (requestFilters) => ({
				url: `/products/?status=ACTIVE${requestFilters}`,
			}),
			providesTags: ["Active"],
		}),
		// getActiveProductsFilters: builder.mutation({
		// 	query: (requestFilters) => ({
		// 		url: `/products/?status=ACTIVE${requestFilters}`,
		// 		method: "GET",
		// 	}),
		// 	providesTags: ["Active"],
		// }),
		getOnActionProducts: builder.query({
			query: () => ({ url: "/products/on-action/" }),
			providesTags: ["OnAction"],
		}),
		getCurrentProduct: builder.query({
			query: (slug) => ({ url: `/products/${slug}` }),
		}),
		// getInactiveProducts: builder.query({
		// 	query: () => ({ url: "/products/?status=INACTIVE/" }),
		// 	invalidatesTags: ["Products"],
		// }),
		getReportsProducts: builder.query({
			query: (slug) => ({
				url: `/products/${slug}/reports/?type=COMMENT`,
			}),
		}),
		createProducts: builder.mutation({
			query: (data) => ({
				url: "/products/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Active", "OnModeration", "OnAction"],
		}),
		deleteProducts: builder.mutation({
			query: (slug) => ({
				url: `/products/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["Active"],
		}),
		putProduct: builder.mutation({
			query: (data) => ({
				url: `/products/${data.slug}/`,
				method: "PUT",
				body: data.data,
			}),
			invalidatesTags: ["Active", "OnModeration", "OnAction"],
		}),
	}),
});
