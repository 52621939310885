import "./auth.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	changeAuthStatus,
	changeStateAuthorized,
	changeUserData,
} from "../../store/authSlice";
import { useFormAndValidation } from "../../utils/useFormAndValidation";
import { authApi } from "../../components/api/authService";
import authErrorImg from "../../images/profile/authError.svg";
import { changeStatePreloader } from "../../store/toolkitSlice";
import { userApi } from "../../components/api/userService";

const Auth = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [unauthorized, setUnauthorized] = useState(true);
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const { values, handleChange, errors, isValid, resetErrors } =
		useFormAndValidation({});
	const [
		fetchAuth,
		{ data: tokens, error: authError, isLoading: isGettingUser },
	] = authApi.useFetchAuthMutation(values);
	const { refetch, isLoading: isRefetchingUser } = userApi.useGetUserQuery();

	const onSubmit = async (e) => {
		e.preventDefault();
		if (values) {
			await fetchAuth(values);
		}
	};
	
	useEffect(() => {
		if (tokens) {
			localStorage.setItem("access", tokens.access);
			localStorage.setItem("refresh", tokens.refresh);
			dispatch(changeStateAuthorized(true));
			navigate("/main");
			refetch();
		}
		if (authError?.status === 401) {
			navigate("/auth");
			setUnauthorized(false);
		}
	}, [tokens, authError]);

	useEffect(() => {
		if(isRefetchingUser === "true" && isGettingUser === "true") {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
		
	}, [isGettingUser, isRefetchingUser]);
	return (
		<form className="auth">
			<div className="container">
				{pageWidth > 450 ? (
					<h2 className="auth__text">Войти</h2>
				) : (
					<h3 className="auth__text">Войти</h3>
				)}
				<div className="inputs">
					<div className="input__auth">
						<input
							className={
								unauthorized
									? "email"
									: "email input__unauthorized"
							}
							id="email"
							name="email"
							type="email"
							value={values.email || ""}
							onChange={handleChange}
							required
							placeholder="Email"
						/>
						<span className={!isValid ? "error" : ""}>
							{errors.email}
						</span>
					</div>
					<div className="input__auth">
						<input
							className={
								unauthorized
									? "password"
									: "password input__unauthorized"
							}
							id="password"
							name="password"
							type="password"
							value={values.password || ""}
							onChange={handleChange}
							required
							placeholder="Пароль"
						/>
						<span className={!isValid ? "error" : ""}>
							{errors.password}
						</span>
					</div>
					{!unauthorized && (
						<div className="auth__error">
							<img src={authErrorImg} alt="authError" />
							<span className={"error"}>
								Неверный логин или пароль
							</span>
						</div>
					)}
				</div>
				<button
					className="auth__btn"
					type="submit"
					onClick={onSubmit}
					disabled={!isValid}>
					<h4 className="auth__btn-text">Войти</h4>
				</button>
				<button
					type="button"
					onClick={() => dispatch(changeAuthStatus("forgot"))}>
					<span className="forgot">Забыли пароль?</span>
				</button>
			</div>
		</form>
	);
};
export default Auth;
