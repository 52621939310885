import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/buttons/button";
import "./return.css";
import {
	changeStateOpenPopupReturn,
	changeStateOpenPopupReturnRequest,
} from "../../../store/popupSlice";
import { productsModerationApi } from "../../api/products/moderationService";

export default function Return() {
	const dispatch = useDispatch();
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const openPopupReturn = useSelector((state) => state.popup.openPopupReturn);
	const [comment, setComment] = useState("");
	function HandleSubmitRefuseReturn() {
		dispatch(changeStateOpenPopupReturn(false));
	}
	const [returnProducts, { error: Error }] =
		productsModerationApi.useReturnProductsMutation();

	async function HandleSubmitApproveReturn(e) {
		e.preventDefault();
		dispatch(changeStateOpenPopupReturnRequest(true));
		await returnProducts({ slug: cardInfo?.slug, text: comment });
		dispatch(changeStateOpenPopupReturn(false));
	}

	useEffect(() => {
		console.log("Error: ", Error);
	}, [Error]);

	return (
		openPopupReturn && (
			<div className="return">
				<h2>{cardInfo.title}</h2>
				<div className="return__block">
					<p className="return__text">Комментарий</p>
					<textarea
						placeholder="Укажите причину отклонения"
						className="return__textarea"
						name="textarea"
						id="textarea"
						cols="30"
						rows={"5"}
						onChange={(e) => setComment(e.target.value)}></textarea>
				</div>
				<div className="return__buttons">
					<button
						className="button__return"
						type="button"
						onClick={HandleSubmitRefuseReturn}>
						<Button textButton="Отменить" type={"secondary"} />
					</button>
					<button
						className="button__return"
						type="submit"
						onClick={HandleSubmitApproveReturn}>
						<Button textButton="Отклонить" type={"primary"} />
					</button>
				</div>
			</div>
		)
	);
}
