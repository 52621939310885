import React, { useEffect, useState } from "react";

export default function CreateCardDate() {
	const [date, setDate] = useState();
	useEffect(() => {
		let dateNow = new Date();
		getDate(dateNow);
	}, []);

	function getDate(dateNow) {
		let result = "";
		result = dateNow.getUTCDate() + " ";
		switch (dateNow.getUTCMonth() + 1) {
			case 1:
				result = result + "янв ";
				break;
			case 2:
				result = result + "фев ";
				break;
			case 3:
				result = result + "мар ";
				break;
			case 4:
				result = result + "апр ";
				break;
			case 5:
				result = result + "май ";
				break;
			case 6:
				result = result + "июн ";
				break;
			case 7:
				result = result + "июл ";
				break;
			case 8:
				result = result + "авг ";
				break;
			case 9:
				result = result + "сен ";
				break;
			case 10:
				result = result + "окт ";
				break;
			case 11:
				result = result + "ноя ";
				break;
			case 12:
				result = result + "дек ";
				break;
			default:
				break;
		}
		result = result + dateNow.getUTCFullYear();
		setDate(result);
	}
	return <p className="date">{date}</p>;
}
