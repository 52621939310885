import React, { useEffect, useState } from "react";
import "./createCardInput.css";
import loadFile from "../../../images/createCard/loadFile-icon.svg";
import delFile from "../../../images/createCard/deleteImageNewCard.svg";
import Button from "../buttons/button";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

export default function InputFiles({ onSetFile, filesError }) {
	const [image, setImage] = useState();
	const [imageURL, setImageURL] = useState();
	const addedFiles = [];
	const addedImagesURL = [];
	let counter = 1;
	const [play, setPlay] = useState(false);
	const fileType = useSelector((state) => state.filters.fileType);
	const cardInfo = useSelector((state) => state.popup.cardInfo);

	function handleOnChange(e) {
		if (!e.target.files.length) {
			return;
		}
		e.preventDefault();
		const arr = Array.from(e.target.files);
		for (let i = 0; i < arr.length; i++) {
			counter++;
			arr[i]._id = counter;
			addedFiles.push(arr[i]);
			let urlImageObject = {
				_id: counter,
				url: URL.createObjectURL(e.target.files[i]),
			};
			addedImagesURL.push(urlImageObject);
		}
		setImageURL(addedImagesURL);
		onSetFile(addedFiles);
		setImage(addedFiles);
	}

	useEffect(() => {
		if (cardInfo?.status === "ON_REWORK" || cardInfo?.status === "ACTIVE") {
			setImage(cardInfo?.file);
			setImageURL(cardInfo?.file);
		}
	}, []);
	const videoStyle = {
		borderRadius: "8px",
		objectFit: "contain",
		height: "100%",
		overflow: "hidden",
	};

	function playVideoHandler() {
		if (fileType === "Video") {
			setPlay(true);
		}
	}

	function stopVideoHandler() {
		if (fileType === "Video") {
			setPlay(false);
		}
	}

	function handleDeleteFiles() {
		setImage();
		onSetFile();
	}

	return (
		<div
			className={`newCardClear__input-photo ${
				filesError ? "filesError" : ""
			}`}
			onMouseEnter={playVideoHandler}
			onMouseLeave={stopVideoHandler}>
			{image ? (
				<>
					{fileType === "Video" ? (
						<ReactPlayer
							url={cardInfo.file}
							style={videoStyle}
							playing={play}
						/>
					) : (
						<div className="newCards__container">
							{imageURL?.map((image) => (
								<img
									key={image._id}
									src={image.url}
									className="newCardClear__selected"
									alt="new file"
								/>
							))}
						</div>
					)}
					<button
						className="newCardClear__delete-file"
						type="button"
						onClick={handleDeleteFiles}>
						<img src={delFile} alt="delete file" />
					</button>
				</>
			) : (
				<div className="loadFileContainer">
					<img src={loadFile} alt="loadFileIcon" />
					<h3 className="newCardClear__input-text">
						Перетащите фото или видео сюда
					</h3>
					<p>или нажмите на кнопку</p>
					<input
						type="file"
						name="file"
						id="file"
						multiple
						accept="image/*, .pdf, .png, .jpg, .ai, .dwg, .dwt, .dxf, .psd, video/*, .mp4"
						className="newCardClear__photo"
						onChange={handleOnChange}
					/>
					<label htmlFor="file" className="label__photo"></label>
					<Button textButton="Выбрать файл" type={"primary"} />
				</div>
			)}
		</div>
	);
}
