import { createSlice } from "@reduxjs/toolkit";

const newProductDataSlice = createSlice({
	name: "newProductDataSlice",
	initialState: {
		brands: [],
		brand_models: [],
		categories: [],
		colors: [],
		services: [],
		tags: [],
	},
	reducers: {
		setBrands(state, action) {
			state.brands = action.payload;
		},
		setBrand_models(state, action) {
			state.brand_models = action.payload;
		},
		setCategories(state, action) {
			state.categories = action.payload;
		},
		setColors(state, action) {
			state.colors = action.payload;
		},
		setServices(state, action) {
			state.services = action.payload;
		},
		setTags(state, action) {
			state.tags = action.payload;
		},
	},
});

export default newProductDataSlice.reducer;
export const {
	setBrands,
	setBrand_models,
	setCategories,
	setColors,
	setServices,
	setTags,
} = newProductDataSlice.actions;
