import { authApi } from "./authService";

export const tagsApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addTags: builder.mutation({
			query: (title) => ({
				url: "/tags/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Tags"],
		}),
		putTags: builder.mutation({
			query: (data) => ({
				url: `/tags/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
				},
			}),
			invalidatesTags: ["Tags"],
		}),
		deleteTags: builder.mutation({
			query: (slug) => ({
				url: `/tags/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Tags"],
		}),
		getTags: builder.query({
			query: () => ({ url: "/tags/" }),
			providesTags: ["Tags"],
		}),
	}),
});
