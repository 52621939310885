import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./change.css";
import Button from "../../ui/buttons/button";
import successFetch from "../../../images/profile/successFetch.svg";
import {
	changeStateOpenPopupPostSuccess,
	changeStateOpenPopupReturn,
	changeStateOpenPopupReturnRequest,
} from "../../../store/popupSlice";
import popupChangeNameClose from "../../../images/tools/close.svg";
import { useGetDate } from "../../../utils/useGetDate";
import Return from "../return/return";

export default function ApprovePopup() {
	const [date, setDate] = useState();
	const [openPopup, setOpenPopup] = useState(false);
	const { cardDate, getCardDate } = useGetDate({ date });
	const openPopupPostSuccess = useSelector(
		(state) => state.popup.openPopupPostSuccess
	);
	const openPopupReturnRequest = useSelector(
		(state) => state.popup.openPopupReturnRequest
	);
	const openPopupReturn = useSelector((state) => state.popup.openPopupReturn);
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const comment = useSelector((state) => state.toolkit.comment);
	const dispatch = useDispatch();

	useEffect(() => {
		let dateCard = new Date(cardInfo?.created_at);
		setDate(dateCard);
	}, [cardInfo]);

	useEffect(() => {
		if (date) getCardDate(date);
	}, [date]);

	function handleCloseAllPopups() {
		dispatch(changeStateOpenPopupPostSuccess(false));
		dispatch(changeStateOpenPopupReturnRequest(false));
		dispatch(changeStateOpenPopupReturn(false));
	}

	useEffect(() => {
		setOpenPopup(
			openPopupPostSuccess || openPopupReturnRequest || openPopupReturn
		);
	}, [openPopupPostSuccess, openPopupReturnRequest, openPopupReturn]);

	return (
		openPopup && (
			<form className="popupChangeName">
				<button
					className="overlay"
					type="button"
					onClick={handleCloseAllPopups}></button>
				<div className="change">
					<button type="button" className="change__close">
						<img
							onClick={handleCloseAllPopups}
							src={popupChangeNameClose}
							alt="close-button"
							className="popupChangeName__close"
						/>
					</button>
					{openPopupPostSuccess && (
						<img
							src={successFetch}
							className="successFetch"
							alt="successFetch"
						/>
					)}
					{openPopupReturn && (
						<p className="popup__card-return">
							{cardInfo.author.full_name} &#183; {cardDate}
						</p>
					)}
					{openPopupPostSuccess ? (
						<h2>
							Успешно!
							<br />
							Вы опубликовали карточку
						</h2>
					) : (
						openPopupReturnRequest && (
							<h2>
								Отправлено!
								<br />
								на доработку
							</h2>
						)
					)}

					{openPopupReturn ? (
						<Return />
					) : (
						<>
							<div className="approve__data">
								<img
									src={cardInfo.file}
									alt="cardImage"
									className="approve__cardImage"
								/>
								<p className="approve__title">
									{comment === "" ? cardInfo.title : comment}
								</p>
							</div>
							<div className="newCardButtons">
								<button
									className="buttonPostSuccess"
									type="button"
									onClick={handleCloseAllPopups}>
									<Button
										textButton="Закрыть"
										type={"secondary"}
									/>
								</button>
							</div>
						</>
					)}
				</div>
			</form>
		)
	);
}
