import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authApi } from "../components/api/authService";
import accountSettingsSlice from "./accountSettingsSlice";
import authSlice from "./authSlice";
import catalogSlice from "./catalogSlice";
import popupSlice from "./popupSlice";
import toolkitSlice from "./toolkitSlice";
import newProductDataSlice from "./newProductDataSlice";
import filtersSlice from "./filtersSlice";

const rootReducer = combineReducers({
	toolkit: toolkitSlice,
	popup: popupSlice,
	auth: authSlice,
	catalog: catalogSlice,
	account: accountSettingsSlice,
	product: newProductDataSlice,
	filters: filtersSlice,
	[authApi.reducerPath]: authApi.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(authApi.middleware),
});
