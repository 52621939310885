import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = "https://photobaza.com/api/v1";

export const authApi = createApi({
	reducerPath: "api",
	tagTypes: [
		"User",
		"Articles",
		"Brands",
		"Models",
		"Categories",
		"Colors",
		"Products",
		"Services",
		"Tags",
		"OnAction",
		"OnModeration",
		"OnRework",
		"Active",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("access");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		fetchAuth: builder.mutation({
			query: (values) => ({
				url: "/auth/login/",
				method: "POST",
				body: {
					email: values.email,
					password: values.password,
				},
			}),
		}),
		verifyToken: builder.mutation({
			query: () => ({
				url: "/auth/token/verify/",
				method: "POST",
				body: {
					token: localStorage.getItem("access"),
				},
			}),
		}),
		refreshToken: builder.mutation({
			query: () => ({
				url: "/auth/token/refresh/",
				method: "POST",
				body: {
					refresh: localStorage.getItem("refresh"),
				},
			}),
		}),
	}),
});
