import React, { useEffect } from "react";
import "./myProducts.css";
import Card from "../../../components/card/card";
import { changeStatePreloader } from "../../../store/toolkitSlice";
import { useDispatch, useSelector } from "react-redux";
import { productsModerationApi } from "../../../components/api/products/moderationService";

export default function OnModeration() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.userData);
	const { data: productsOnModeration, isLoading: isOnModeration } =
		productsModerationApi.useGetOnModerationProductsQuery(user?.id);

	useEffect(() => {
		if (isOnModeration === true)
			dispatch(changeStatePreloader(true));
		else dispatch(changeStatePreloader(false));
	}, [isOnModeration]);

	return (
		<div className="my_products">
			{productsOnModeration?.map((card) => (
				<Card key={card.id} cardInfo={card} />
			))}
		</div>
	);
}
