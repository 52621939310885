import React, { useEffect } from "react";
import "./allFilters.css";
import { useDispatch, useSelector } from "react-redux";
import { changeStateOpenAllFiltersPopup } from "../../../store/popupSlice";
import closeOverlay from "../../../images/popup/close-overlay-create-card.svg";
import { brandsApi } from "../../api/brandsService";
import { categoriesApi } from "../../api/categoriesService";
import { modelsApi } from "../../api/modelsService";
import { colorsApi } from "../../api/colorsService";
import { servicesApi } from "../../api/servicesService";
import Toggle from "../../ui/toggle/toggle";
import AllFiltersDropdown from "../../ui/dropdown/allFiltersDropdown";
import { changeStatePreloader } from "../../../store/toolkitSlice";
import { tagsApi } from "../../api/tagsService";

const AllFilters = () => {
	// const pageWidth = useSelector((state) => state.toolkit.pageWidth);

	const dispatch = useDispatch();
	const filtersBrand = useSelector(
		(state) => state.filters.filtersBrand[0]?.slug
	);
	const openAllFiltersPopup = useSelector(
		(state) => state.popup.openAllFiltersPopup
	);
	const { data: brands, isLoading: isFetchingBrands } =
		brandsApi.useGetBrandsQuery();
	const { data: categories, isLoading: isFetchingCategories } =
		categoriesApi.useGetCategoriesQuery();
	const { data: brands_models, isLoading: isFetchingModels } =
		modelsApi.useGetModelsQuery(
			filtersBrand ? `?brand=${filtersBrand}` : ""
		);
	const { data: colors, isLoading: isFetchingColors } =
		colorsApi.useGetColorsQuery();
	const { data: services, isLoading: isFetchingServices } =
		servicesApi.useGetServicesQuery();
	const { data: tags, isLoading: isFetchingTags } = tagsApi.useGetTagsQuery();

	useEffect(() => {
		if (
			isFetchingBrands === true &&
			isFetchingCategories === true &&
			isFetchingModels === true &&
			isFetchingColors === true &&
			isFetchingTags === true &&
			isFetchingServices === true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		isFetchingBrands,
		isFetchingCategories,
		isFetchingModels,
		isFetchingColors,
		isFetchingServices,
		isFetchingTags,
	]);

	return (
		openAllFiltersPopup && (
			<div className="allFiltersPopup">
				<button
					className="allFilters__overlay"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenAllFiltersPopup(false))
					}
				/>
				<div className="allFilters__container">
					<div className="allFiltersHeader">
						<h3>Все фильтры</h3>
						<button type="button">
							<img
								onClick={() =>
									dispatch(
										changeStateOpenAllFiltersPopup(false)
									)
								}
								src={closeOverlay}
								alt="close-button"
								className="callFilters__close"
							/>
						</button>
					</div>
					<AllFiltersDropdown
						text="Марка авто"
						dropdownData={brands}
					/>
					<AllFiltersDropdown
						text="Модель авто"
						dropdownData={brands_models}
					/>
					<AllFiltersDropdown
						text="Категории"
						dropdownData={categories}
					/>
					<AllFiltersDropdown text="Цвет" dropdownData={colors} />
					<AllFiltersDropdown text="Тэги" dropdownData={tags} />
					<AllFiltersDropdown text="Услуги" dropdownData={services} />
					<Toggle all={"all"} />
				</div>
			</div>
		)
	);
};

export default AllFilters;
