import { authApi } from "./authService";

export const servicesApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addServices: builder.mutation({
			query: (title) => ({
				url: "/services/",
				method: "POST",
				body: {
					title: title,
				},
			}),
			invalidatesTags: ["Services"],
		}),
		putServices: builder.mutation({
			query: (data) => ({
				url: `/services/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
				},
			}),
			invalidatesTags: ["Services"],
		}),
		deleteServices: builder.mutation({
			query: (slug) => ({
				url: `/services/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Services"],
		}),
		getServices: builder.query({
			query: () => ({ url: "/services/" }),
			providesTags: ["Services"],
		}),
	}),
});
