import React, { useEffect } from "react";
import "./myProducts.css";
import Card from "../../../components/card/card";
import { productsApi } from "../../../components/api/productsService";
import { changeStatePreloader } from "../../../store/toolkitSlice";
import { useDispatch } from "react-redux";

export default function OnAction() {
	const dispatch = useDispatch();
	const { data: productsOnAction, isLoading: isOnAction } =
		productsApi.useGetOnActionProductsQuery();
	useEffect(() => {
		dispatch(changeStatePreloader(isOnAction));
	}, [isOnAction]);
	// console.log(productsOnAction);

	return (
		<div className="my_products">
			{productsOnAction?.map((card) => (
				<Card key={card.id} cardInfo={card} />
			))}
		</div>
	);
}
