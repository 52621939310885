import { authApi } from "../authService";

export const productsModerationApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		getOnReworkProducts: builder.query({
			query: () => ({
				url: "/products/?status=ON_REWORK",
			}),
			providesTags: ["OnRework"],
		}),
		getOnModerationProducts: builder.query({
			query: (id) => ({
				url: `/products/on-moderation`,
				// url: `/users/${id}/products/?status=ON_MODERATION`,
			}),
			providesTags: ["OnModeration"],
		}),
		approveProducts: builder.mutation({
			query: (slug) => ({
				url: `/products/${slug}/approve/`,
				method: "POST",
			}),
			invalidatesTags: ["Active", "OnAction", "OnModeration"],
		}),

		returnProducts: builder.mutation({
			query: (data) => ({
				url: `/products/${data.slug}/reports/`,
				method: "POST",
				body: { reason_text: data.text, type: "COMMENT" },
			}),
			invalidatesTags: ["OnAction"],
		}),
	}),
});
