import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
	name: "openPopup",
	initialState: {
		openPopup: false,
		openPopupChangeName: false,
		openPopupChangePassword: false,
		stepChangePassword: 1,
		cardInfo: null,
		openPopupCreateCard: false,
		openPopupAddCategory: false,
		openPopupActions: "",
		openPopupChangeNameCategory: false,
		openAllFiltersPopup: false,
		openPopupPostSuccess: false,
		openPopupReturn: false,
		openPopupReturnRequest: false,
	},
	reducers: {
		changeStateOpenPopup(state, action) {
			state.openPopup = action.payload;
		},
		changeStateOpenPopupChangeName(state, action) {
			state.openPopupChangeName = action.payload;
		},
		changeStateOpenPopupChangePassword(state, action) {
			state.openPopupChangePassword = action.payload;
		},
		setStepChangePassword(state, action) {
			state.stepChangePassword = action.payload;
		},
		setCardInfo(state, action) {
			state.cardInfo = action.payload;
		},
		changeStateOpenPopupCreateCard(state, action) {
			state.openPopupCreateCard = action.payload;
		},
		changeStateOpenPopupAdd(state, action) {
			state.openPopupAddCategory = action.payload;
		},
		changeStateOpenPopupActions(state, action) {
			state.openPopupActions = action.payload;
		},
		changeStateOpenPopupChangeNameCategory(state, action) {
			state.openPopupChangeNameCategory = action.payload;
		},
		changeStateOpenAllFiltersPopup(state, action) {
			state.openAllFiltersPopup = action.payload;
		},
		changeStateOpenPopupPostSuccess(state, action) {
			state.openPopupPostSuccess = action.payload;
		},
		changeStateOpenPopupReturn(state, action) {
			state.openPopupReturn = action.payload;
		},
		changeStateOpenPopupReturnRequest(state, action) {
			state.openPopupReturnRequest = action.payload;
		},
	},
});

export default popupSlice.reducer;
export const {
	changeStateOpenPopup,
	setCardInfo,
	changeStateOpenPopupChangeName,
	changeStateOpenPopupChangePassword,
	setStepChangePassword,
	changeStateOpenPopupCreateCard,
	changeStateOpenPopupAdd,
	changeStateOpenPopupActions,
	changeStateOpenPopupChangeNameCategory,
	changeStateOpenAllFiltersPopup,
	changeStateOpenPopupPostSuccess,
	changeStateOpenPopupReturn,
	changeStateOpenPopupReturnRequest,
} = popupSlice.actions;
