import "./account.css";
import React, { useEffect } from "react";
import photo from "../../images/profile/photo.svg";
import avg_pace from "../../images/profile/avg_pace.svg";
import error from "../../images/profile/error.svg";
import account_circle from "../../images/profile/account_circle.svg";
import grid_view from "../../images/profile/grid_view.svg";
import logout from "../../images/profile/logout.svg";
import ProfileSettings from "./profileSettings";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStateAuthorized } from "../../store/authSlice";
import CatalogSettings from "./catalogSettings";
import MyProducts from "./myProducts/myProducts";
import OnModeration from "./myProducts/onModeration";
import { changeStatePreloader } from "../../store/toolkitSlice";
import OnAction from "./myProducts/onAction";
import { productsApi } from "../../components/api/productsService";
import { changeStatusCatalog } from "../../store/catalogSlice";
import { userApi } from "../../components/api/userService";
const Account = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Exit = () => {
		localStorage.clear();
		navigate("/auth");
		dispatch(changeStateAuthorized(false));
	};
	const { data: user } = userApi.useGetUserQuery();
	const { data: productsOnAction, isLoading: isOnAction } =
		productsApi.useGetOnActionProductsQuery();
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const statusCatalog = useSelector((state) => state.catalog.statusCatalog);
	useEffect(() => {
		dispatch(changeStatePreloader(isOnAction));
	}, [isOnAction]);

	return (
		<section className="account">
			<div className="account__bg">
				{pageWidth > 990 && (
					<div className="account__info">
						<div className="account__block">
							{user && (
								<div className="account__head">
									{user?.picture ? (
										<img
											className="account__photo"
											alt="фото пользователя"
											src={user.picture}
										/>
									) : (
										<h3 className="account__photo">
											{user?.full_name?.indexOf(" ") > -1
												? user?.full_name
														.match(/[А-ЯA-Z]/g)
														.join("")
												: Array.from(
														user?.full_name
												  )[0]}
										</h3>
									)}
									<h3 className="account__name">
										{user?.full_name}
									</h3>
									<p className="account__role">
										{user?.is_admin ? "Администратор" : ""}
									</p>
								</div>
							)}
							<div className="account__photos">
								<div className="account__photos-textbox">
									<p className="account__photos-text">Фото</p>
								</div>
								<button
									type="button"
									onClick={() =>
										dispatch(changeStatusCatalog("main"))
									}
									className="account__photos-box">
									<img
										src={photo}
										alt="action"
										className="account__image-action"
									/>
									<h4 className="account__action-text">
										Мои фото
									</h4>
								</button>
								<button
									type="button"
									onClick={() =>
										dispatch(
											changeStatusCatalog("moderation")
										)
									}
									className="account__photos-box">
									<img
										src={avg_pace}
										alt="action"
										className="account__image-action"
									/>
									<h4 className="account__action-text">
										На модерации
									</h4>
								</button>
								<button
									type="button"
									onClick={() =>
										dispatch(changeStatusCatalog("action"))
									}
									className="account__photos-box">
									<img
										src={error}
										alt="action"
										className="account__image-action"
									/>
									<h4 className="account__action-text">
										Требуют действий
									</h4>
									{productsOnAction?.length !== 0 && (
										<div className="count__on-action-cards">
											{productsOnAction?.length}
										</div>
									)}
								</button>
							</div>
							<div className="account__settings">
								<div className="account__photos-textbox">
									<p className="account__photos-text">
										Настройки
									</p>
								</div>
								<button
									type="button"
									onClick={() =>
										dispatch(changeStatusCatalog("profile"))
									}
									className="account__photos-box">
									<img
										src={account_circle}
										alt="action"
										className="account__image-action"
									/>
									<h4 className="account__action-text">
										Профиль
									</h4>
								</button>
								{user?.is_admin && (
									<button
										type="button"
										onClick={() =>
											dispatch(
												changeStatusCatalog("catalog")
											)
										}
										className="account__photos-box">
										<img
											src={grid_view}
											alt="action"
											className="account__image-action"
										/>
										<h4 className="account__action-text">
											Каталог
										</h4>
									</button>
								)}
							</div>
						</div>
						<button
							type="button"
							className="account__photos-box account__photos-box_exit"
							onClick={Exit}>
							<img
								src={logout}
								alt="action"
								className="account__image-action"
							/>
							<h4 className="account__action-text">Выйти</h4>
						</button>
					</div>
				)}
				<div className="account__cards">
					{statusCatalog === "main" && <MyProducts id={user?.id} />}
					{statusCatalog === "action" && <OnAction />}
					{statusCatalog === "moderation" && <OnModeration />}
					{statusCatalog === "profile" && <ProfileSettings />}
					{statusCatalog === "catalog" && <CatalogSettings />}
				</div>
			</div>
		</section>
	);
};
export default Account;
