import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productsApi } from "../../components/api/productsService";
import CatalogItem from "../../components/profile/catalogItem";
import AllItems from "../../components/profile/allItems";
import "./catalogSettings.css";
import { tagsApi } from "../../components/api/tagsService";
import { brandsApi } from "../../components/api/brandsService";
import { categoriesApi } from "../../components/api/categoriesService";
import { modelsApi } from "../../components/api/modelsService";
import { colorsApi } from "../../components/api/colorsService";
import { servicesApi } from "../../components/api/servicesService";
// import { articlesApi } from "../../components/api/articlesService";
import { changeStatePreloader } from "../../store/toolkitSlice";

export default function CatalogInfo() {
	const dispatch = useDispatch();
	const openFolder = useSelector((state) => state.account.openFolder);
	const brandSlug = useSelector((state) => state.toolkit.brandForModelsSlug);
	const { data: brands, isLoading: isFetchingBrands } =
		brandsApi.useGetBrandsQuery();
	const { data: categories, isLoading: isFetchingCategories } =
		categoriesApi.useGetCategoriesQuery();
	const { data: models, isLoading: isFetchingModels } =
		modelsApi.useGetNeedModelsQuery(brandSlug ? `?brand=${brandSlug}` : "");
	const { data: colors, isLoading: isFetchingColors } =
		colorsApi.useGetColorsQuery();
	const { data: services, isLoading: isFetchingServices } =
		servicesApi.useGetServicesQuery();
	const { data: products, isLoading: isFetchingProducts } =
		productsApi.useGetProductsQuery();
	// const { data: articles, isLoading: isFetchingArticles } =
	// 	articlesApi.useGetArticlesQuery();
	const { data: tags, isLoading: isFetchingTags } = tagsApi.useGetTagsQuery();
	useEffect(() => {
		if (
			isFetchingBrands === true &&
			isFetchingCategories === true &&
			isFetchingModels === true &&
			isFetchingColors === true &&
			isFetchingProducts === true &&
			// isFetchingArticles === true &&
			isFetchingTags === true &&
			isFetchingServices === true
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		isFetchingBrands,
		isFetchingCategories,
		isFetchingModels,
		isFetchingColors,
		isFetchingServices,
		isFetchingTags,
		isFetchingProducts,
		// isFetchingArticles,
	]);
	return (
		<ul className="catalog__info">
			{openFolder === "" ? (
				<AllItems />
			) : openFolder === "Марка авто" ? (
				brands?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={true}
						brand={item?.slug}
					/>
				))
			) : openFolder === "Модель авто" ? (
				models.models?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
					/>
				))
			) : openFolder === "Категории" ? (
				categories?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
					/>
				))
			) : openFolder === "Цвет" ? (
				colors?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
						color={item?.hex_code}
					/>
				))
			) : openFolder === "Услуги" ? (
				services?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
					/>
				))
			) : openFolder === "Продукты" ? (
				products?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
					/>
				))
			) : // ) : openFolder === "Артикулы" ? (
			// 	articles?.map((item) => (
			// 		<CatalogItem
			// 			key={item?.id}
			// 			slug={item?.slug}
			// 			title={item?.title}
			// 			isFolder={false}
			// 		/>
			// 	))
			openFolder === "Тэги" ? (
				tags?.map((item) => (
					<CatalogItem
						key={item?.id}
						slug={item?.slug}
						title={item?.title}
						isFolder={false}
					/>
				))
			) : (
				<></>
			)}
		</ul>
	);
}
