import { createSlice } from "@reduxjs/toolkit";

const toolkitSlice = createSlice({
	name: "dropdownSlice",
	initialState: {
		clickedElement: "",
		pageWidth: 0,
		slug: "",
		brandForModelsSlug: "",
		preloader: false,
		comment: "",
	},
	reducers: {
		addClickedElement(state, action) {
			state.clickedElement = action.payload;
		},
		changePageWidth(state, action) {
			state.pageWidth = action.payload;
		},
		changeSlug(state, action) {
			state.slug = action.payload;
		},
		changeBrandForModelsSlug(state, action) {
			state.brandForModelsSlug = action.payload;
		},
		changeStatePreloader(state, action) {
			state.preloader = action.payload;
		},
		changeCommentText(state, action) {
			state.comment = action.payload;
		},
	},
});

export default toolkitSlice.reducer;
export const {
	addClickedElement,
	changePageWidth,
	changeSlug,
	changeBrandForModelsSlug,
	changeStatePreloader,
	changeCommentText,
} = toolkitSlice.actions;
