import React from "react";
import "./change.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/buttons/button";
import {
	changeStateOpenPopupChangePassword,
	setStepChangePassword,
} from "../../../store/popupSlice";

export default function ChangePassword() {
	const dispatch = useDispatch();
	const openPopupChangePassword = useSelector(
		(state) => state.popup.openPopupChangePassword
	);
	const stepChangePassword = useSelector(
		(state) => state.popup.stepChangePassword
	);

	const Submit = () => {
		dispatch(setStepChangePassword(1));
		dispatch(changeStateOpenPopupChangePassword(false));
	};

	return (
		openPopupChangePassword && (
			<div className="popupChangeName">
				<button
					className="overlay"
					type="button"
					onClick={() =>
						dispatch(changeStateOpenPopupChangePassword(false))
					}></button>
				<div className="change">
					<div className="steps">
						<p className="steps__text">Шаг</p>
						<h5 className="step">{`${stepChangePassword} из 2`}</h5>
					</div>
					<h2>
						{stepChangePassword === 1
							? "Изменить пароль"
							: "Новый пароль"}
					</h2>
					<input
						className="input"
						id="password"
						name="password"
						type="password"
						// value={values.password || ""}
						// onChange={handleChange}
						required
					/>
					{stepChangePassword === 2 ? (
						<input
							className="input"
							id="confirm"
							name="confirm"
							type="password"
							// value={values.confirm || ""}
							// onChange={handleChange}
							required
						/>
					) : (
						<></>
					)}
					<div className="buttons">
						<button
							className="buttonNewCard"
							type="button"
							onClick={() =>
								dispatch(
									changeStateOpenPopupChangePassword(false)
								)
							}>
							<Button textButton="Отмена" type={"secondary"} />
						</button>
						{stepChangePassword === 1 ? (
							<button
								className="buttonNewCard"
								type="button"
								onClick={() =>
									dispatch(setStepChangePassword(2))
								}>
								<Button textButton="Далее" type={"primary"} />
							</button>
						) : (
							<button
								className="buttonNewCard"
								type="submit"
								onClick={Submit}>
								<Button
									textButton="Сохранить"
									type={"primary"}
								/>
							</button>
						)}
					</div>
				</div>
			</div>
		)
	);
}
