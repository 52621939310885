import { authApi } from "./authService";

export const modelsApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		addModels: builder.mutation({
			query: (data) => ({
				url: "/brands_models/",
				method: "POST",
				body: {
					title: data.title,
					brand: data.brand,
				},
			}),
			invalidatesTags: ["Models"],
		}),
		putModels: builder.mutation({
			query: (data) => ({
				url: `/brands_models/${data.slug}`,
				method: "PUT",
				body: {
					title: data.title,
					brand: data.brand,
				},
			}),
			invalidatesTags: ["Models"],
		}),
		deleteModels: builder.mutation({
			query: (slug) => ({
				url: `/brands_models/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Models"],
		}),
		getModels: builder.query({
			query: (slug) => ({ url: `/brands_models/${slug}` }),
			providesTags: ["Models"],
		}),
		getNeedModels: builder.query({
			query: (slug) => ({ url: `/brands/${slug}` }),
			providesTags: ["Models"],
		}),
	}),
});
