import { authApi } from "./authService";

export const userApi = authApi.injectEndpoints({
	endpoints: (builder) => ({
		getUser: builder.query({
			query: () => ({ url: "/users/me/" }),
			providesTags: ["User"],
		}),
		getUserProducts: builder.query({
			query: (id) => ({ url: `/users/${id}/products` }),
		}),
		providesTags: ["User"],
		putUser: builder.mutation({
			query: (data) => ({
				url: `/users/${data.id}`,
				method: "PATCH",
				body: {
					email: data.email,
					full_name: data.userName,
					picture: data.picture,
				},
			}),
			invalidatesTags: ["User"],

		}),
	}),
});
