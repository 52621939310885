import { useState } from "react";
import { useDispatch } from "react-redux";
import {
	setFiltersBrand,
	setFiltersCategory,
	setFiltersColor,
	setFiltersModel,
	setFiltersService,
	setFiltersTag,
} from "../store/filtersSlice";

export function useDropdownValues({ values, text }) {
	const [count, setCount] = useState(0);
	const data = [];
	let i = 0;
	const dispatch = useDispatch();
	function handleChangeValue() {
		switch (text) {
			case "Категории":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersCategory(data));
				break;
			case "Марка авто":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersBrand(data));
				break;
			case "Модель авто":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersModel(data));
				break;
			case "Услуги":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersService(data));
				break;
			case "Цвет":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersColor(data));
				break;
			case "Тэги":
				values.forEach((value) => {
					i++;
					data.push(value);
				});
				setCount(i);
				dispatch(setFiltersTag(data));
				break;
			default:
				break;
		}
	}

	return { count, handleChangeValue };
}
