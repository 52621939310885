import React from "react";
import "./404.css";
import notFound from "../../images/mainImages/notFound.svg";
import text from "../../images/mainImages/404-text.svg";
import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<div className="notFound">
			<img src={notFound} alt="notFound" />
			<h1 className="notFound__h1">Всё, приехали!</h1>
			<span className="notFound__span">
				Кажется, такой страницы не существует, либо ее адрес изменился,
				попробуйте начать с главной
			</span>
			<div className="notFound__link">
				<Link to="/main" className="notFound__link-text">
					На главную
				</Link>
			</div>
			<img src={text} alt="notFound" className="text-not-found" />
		</div>
	);
}
