import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
	name: "authSlice",
	initialState: {
		authStatus: "login",
		authorized: false,
		userName: "",
		userData: [],
	},
	reducers: {
		changeAuthStatus(state, action) {
			state.authStatus = action.payload;
		},
		changeStateAuthorized(state, action) {
			state.authorized = action.payload;
		},
		changeUser(state, action) {
			state.userName = action.payload;
		},
		changeUserData(state, action) {
			state.userData = action.payload;
		},
	},
});

export default authSlice.reducer;
export const {
	changeAuthStatus,
	changeStateAuthorized,
	changeUser,
	changeUserData,
} = authSlice.actions;
