import "./auth.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAuthStatus } from "../../store/authSlice";

const New = () => {
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const dispatch = useDispatch();
	const onSubmit = (e) => {
		e.preventDefault();
		console.log("done");
		dispatch(changeAuthStatus("done"));
	};
	return (
		<div className="auth">
			<div className="container">
				{pageWidth > 450 ? (
					<h2 className="auth__text">Придумайте надежный пароль</h2>
				) : (
					<h3 className="auth__text">Придумайте надежный пароль</h3>
				)}
				<div className="inputs">
					<div className="input__auth">
						<input
							className="password"
							id="password"
							name="password"
							type="password"
							// value={values.email || ""}
							// onChange={handleChange}
							required
						/>
						<span className="placeholder">Новый пароль</span>
						{/* <span
							className={`login__error ${
								!isValid ? "login__error_active" : ""
							}`}>
							{errors.email}
						</span> */}
					</div>
					<div className="input__auth">
						<input
							className="password"
							id="password"
							name="password"
							type="password"
							// value={values.email || ""}
							// onChange={handleChange}
							required
						/>
						<span className="placeholder">Повторите пароль</span>
						{/* <span
							className={`login__error ${
								!isValid ? "login__error_active" : ""
							}`}>
							{errors.email}
						</span> */}
					</div>
				</div>
				<button className="auth__btn" type="submit" onClick={onSubmit}>
					<h4 className="auth__btn-text">Сохранить пароль</h4>
				</button>
			</div>
		</div>
	);
};
export default New;
